import { atom, selector } from 'recoil';
import {
	TShipmentEOS,
	TCargoEOS,
	CAPACITY_TYPE,
	CARGO_TYPE,
	IShipmentServerErrors,
	FREIGHT_MODE
} from '@types';

/* eslint-disable */
const keys = {
	TRIGGER_BOOKING_SUBMIT:	'@Ofload/TriggerBookingSubmit',
	BOOKING_SERVER_ERRORS:	'@Ofload/BookingServerErrors',
	PRICE_SERVER_ERRORS:	'@Ofload/PriceServerErrors',
	EOS_CAPACITY_TYPE:		'@Ofload/CapacityType',
	EOS_SHIPMENT:			'@Ofload/EOSShipment',
	EOS_CARGO:				'@Ofload/EOSCargo',
	EOS_CARGO_TYPE:			'@Ofload/EOSCargoType',
	EOS_TOTAL_QTYS:			'@Ofload/EOSTotalQtys'
}
/* eslint-enable */

export const TriggerBookingSubmit = atom({
	key: keys.TRIGGER_BOOKING_SUBMIT,
	default: false
});

export const BookingServerErrors = atom<IShipmentServerErrors | null>({
	key: keys.BOOKING_SERVER_ERRORS,
	default: null
});

export const PriceServerErrors = atom<IShipmentServerErrors | null>({
	key: keys.PRICE_SERVER_ERRORS,
	default: null
});

export const EOSCapacityType = atom<CAPACITY_TYPE | string>({
	key: keys.EOS_CAPACITY_TYPE,
	default: ''
});

export const blankCargo = {
	id: 0,
	cargo_type_id: 0,
	cargo_trans_type_id: 0,
	pick_from: '',
	pick_to: '',
	drop_from: '',
	drop_to: '',
	pickup_at: '',
	drop_at: '',
	is_ftl: false,
	is_pallet_spaces: false,
	pickup: {
		id: 0,
		address: '',
		block_addr: '',
		business_name: '',
		contact: '',
		phone: '',
		email: '',
		instructions: '',
		slot: '',
		gate_house: '',
		has_time_slot: false,
		is_mandatory: false,
		open_time: '',
		close_time: '',
		carrier_must_book_slot: false,
		carrier_must_call_before: false,
		side_load: false,
		rear_load: false,
		top_load: false,
		lift_gate: false,
		loading_requirements: '',
		needs_ppe: false,
		street: '',
		state: '',
		suburb: '',
		subpost: '',
		postcode: ''
	},
	dropoff: {
		id: 0,
		address: '',
		block_addr: '',
		business_name: '',
		contact: '',
		phone: '',
		email: '',
		instructions: '',
		slot: '',
		gate_house: '',
		has_time_slot: false,
		is_mandatory: false,
		open_time: '',
		close_time: '',
		carrier_must_book_slot: false,
		carrier_must_call_before: false,
		side_load: false,
		rear_load: false,
		top_load: false,
		lift_gate: false,
		loading_requirements: '',
		needs_ppe: false,
		street: '',
		state: '',
		suburb: '',
		subpost: '',
		postcode: ''
	},
	is_begin: false,
	is_done: false,
	has_overweight_pallet: false
}

export const blankShipment = {
	shipper_id: 0,
	team_id: 0,
	logistics_id: 0,
	bus_name: '',
	company_name: '',
	addr_id_pickup: 0,
	addr_id_dropoff: 0,
	pick_addr: '',
	drop_addr: '',
	master_ship_id: 0,
	manifest_id: null,
	reference: '',
	shipper_ref1: '',
	po_reference: null,
	shipper_ref2: null,
	shipper_ref3: null,
	cargo_type_id: 0,
	quote_id: null,
	temperature_id: 0,
	total_weight: '',
	total_volume: '',
	req_truck_size_id: 0,
	req_truck_type_id: null,
	transport_type_id: 0,
	is_stackable: true,
	need_forklift: false,
	need_pallet_jack: false,
	ship_status: 0,
	ship_status_id: 0,
	has_claim: false,
	cargo_id: 0,
	item_number: null,
	qty: 0,
	pallet_qty: 0,
	load_mtr: null,
	weight: null,
	height: '',
	length: '',
	width: '',
	is_ftl: false,
	pick_position: null,
	drop_position: null,
	pickup_at: new Date(),
	drop_at: new Date(),
	pick_from: '',
	pick_to: '',
	drop_from: '',
	drop_to: '',
	created_at: new Date(),
	updated_at: new Date(),
	create_user: '',
	edit_user: null,
	truck_type_name: '',
	cargos: [blankCargo],
	cargo_type: null,
	is_dangerous: undefined,
	dangerous_good_meta_data: [],
	status: null,
	load_security: {
		tension_belt: false,
		edge_protect: false,
		driver_assist_stop: false,
		anti_slip_mat: false,
		multilock: false,
		sideboards: false,
		chains: false,
		stopbars: false,
		cargo_net: false,
		coil: false,
		tir_cable: false,
		asp_pallets: false
	},
	pallet_handling: [],
	freight_mode: '' as FREIGHT_MODE
}

export const EOSShipment = atom<TShipmentEOS>({
	key: keys.EOS_SHIPMENT,
	default: blankShipment
});

export const EOSCargoTotalQtys = selector({
	key: keys.EOS_TOTAL_QTYS,
	get: ({ get }) => {
		const shipment = get(EOSShipment);
		const totals = shipment.cargos.reduce((prevVal, currentVal) => {
			return {
				qty: prevVal.qty += (currentVal.pallet_qty ?? 0),
				spaces: prevVal.spaces += (currentVal.qty ?? 0),
				weight: prevVal.weight += (currentVal.weight ?? 0)
			}
		}, {
			qty: 0,
			spaces: 0,
			weight: 0
		});
		return totals;
	}
});

export const EOSCargo = selector<TCargoEOS[]>({
	key: keys.EOS_CARGO,
	get: ({ get }) => {
		const shipment = get(EOSShipment);
		return shipment.cargos;
	},
	set: ({ get, set }, newValue) => {
		const shipment = get(EOSShipment);
		const newShipment:TShipmentEOS = {
			...shipment,
			cargos: newValue as TCargoEOS[]
		}
		set(EOSShipment, newShipment);
	}
});

export const EOSCargoType = selector<CARGO_TYPE | null>({
	key: keys.EOS_CARGO_TYPE,
	get: ({ get }) => {
		const shipment = get(EOSShipment);
		return shipment.cargo_type;
	},
	set: ({ get, set }, newValue) => {
		const shipment = get(EOSShipment);
		const newShipment = {
			...shipment,
			cargo_type: newValue as CARGO_TYPE
		}
		set(EOSShipment, newShipment);
	}
});

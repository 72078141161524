export enum BUTTON_TYPES {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	TERTIARY = 'TERTIARY',
	POSITIVE = 'POSITIVE',
	NEGATIVE = 'NEGATIVE',
	ACTION = 'ACTION'
}

export enum BUTTON_SIZE {
	LARGE = 'LARGE',
	SMALL = 'SMALL'
}

export enum BUTTON_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM'
}

export enum INPUT_TYPE {
	TEXT = 'TEXT',
	NUMBER = 'NUMBER',
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
	PASSWORD = 'PASSWORD'
}

export enum INPUT_SIZE {
	SMALL = 'SMALL',
	REGULAR = 'REGULAR'
}

export enum SPACER_DIRECTION {
	HORIZONTAL = 'HORIZONTAL',
	VERTICAL = 'VERTICAL'
}

export enum CHIP_TYPE {
	INFO = 'INFO',
	WARN = 'WARN',
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
	NEUTRAL = 'NEUTRAL',
	NEW = 'NEW',
	SECONDARY = 'SECONDARY'
}

export enum CHIP_SIZE {
	LARGE = 'LARGE',
	MEDIUM = 'MEDIUM',
	SMALL = 'SMALL',
}

export enum BUTTON_ACTION_TYPE {
	BUTTON = 'button',
	SUBMIT = 'submit',
	RESET = 'reset'
}

export enum CHIP_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT'
}

export enum INPUT_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT'
}

export enum TEMP_NAMES {
	AMBIENT = 'Ambient',
	CHILLED = 'Chilled',
	FROZEN = 'Frozen'
}

export enum SELECT_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT'
}

export enum TEXT_WEIGHT {
	REGULAR = 'regular',
	LIGHT = 'light',
	SEMI_BOLD = 'semiBold',
	BOLD = 'bold'
}

export enum TOOLTIP_POSITION {
	LEFT = 'left',
	RIGHT = 'right',
	TOP = 'top',
	BOTTOM = 'bottom'
}

export enum DG_OPTIONS {
    LOAD_CONTAINS_BULK_DANGEROUS_GOODS = 'LOAD_CONTAINS_BULK_DANGEROUS_GOODS',
    LOAD_CONTAINS_FULLY_REGULATED_DANGEROUS_GOODS = 'LOAD_CONTAINS_FULLY_REGULATED_DANGEROUS_GOODS',
    LOAD_CONTAINS_LIMITED_QUANTITIES = 'LOAD_CONTAINS_LIMITED_QUANTITIES'
}

export const getDangerousGoodOptionLabel = (option: string): string => {
	switch (option) {
	case DG_OPTIONS.LOAD_CONTAINS_BULK_DANGEROUS_GOODS:
		return 'Load contains bulk dangerous goods';
	case DG_OPTIONS.LOAD_CONTAINS_FULLY_REGULATED_DANGEROUS_GOODS:
		return 'Load contains fully regulated dangerous goods';
	case DG_OPTIONS.LOAD_CONTAINS_LIMITED_QUANTITIES:
		return 'Load contains limited quantities';
	default:
		throw new Error('Invalid Dangerous Good Option');
	}
}

export enum LOAD_SEC_REQ {
    TENSION_BELTS = 'TENSION_BELTS',
    EDGE_PROTECTION_SHIP_THIS_FREQUENTLY = 'EDGE_PROTECTION_SHIP_THIS_FREQUENTLY',
    DRIVER_ASSISTANCE_AT_STOP = 'DRIVER_ASSISTANCE_AT_STOP',
    ANTI_SLIP_MAT = 'ANTI_SLIP_MAT',
    MULTILOCK = 'MULTILOCK',
    IS_SIDEBOARDS_FIVE_PER_FIELD = 'IS_SIDEBOARDS_FIVE_PER_FIELD',
    CHAINS = 'CHAINS',
    STOP_BARS = 'STOP_BARS',
    CARGO_NET = 'CARGO_NET',
    COIL_SUPPORTS = 'COIL_SUPPORTS',
    TIR_CABLE = 'TIR_CABLE',
    ANGLES_STRAPS_PLY_FOR_ALL_PALLETS = 'ANGLES_STRAPS_PLY_FOR_ALL_PALLETS',
	CORFLUTE = 'CORFLUTE'
}

export const getLoadSecReqKey = (requirment: string): string => {
	switch (requirment) {
	case LOAD_SEC_REQ.TENSION_BELTS:
		return 'tension_belt';
	case LOAD_SEC_REQ.EDGE_PROTECTION_SHIP_THIS_FREQUENTLY:
		return 'edge_protect';
	case LOAD_SEC_REQ.DRIVER_ASSISTANCE_AT_STOP:
		return 'driver_assist_stop';
	case LOAD_SEC_REQ.ANTI_SLIP_MAT:
		return 'anti_slip_mat';
	case LOAD_SEC_REQ.MULTILOCK:
		return 'multilock';
	case LOAD_SEC_REQ.IS_SIDEBOARDS_FIVE_PER_FIELD:
		return 'sideboards';
	case LOAD_SEC_REQ.CHAINS:
		return 'chains';
	case LOAD_SEC_REQ.STOP_BARS:
		return 'stopbars';
	case LOAD_SEC_REQ.CARGO_NET:
		return 'cargo_net';
	case LOAD_SEC_REQ.COIL_SUPPORTS:
		return 'coil';
	case LOAD_SEC_REQ.TIR_CABLE:
		return 'tir_cable';
	case LOAD_SEC_REQ.ANGLES_STRAPS_PLY_FOR_ALL_PALLETS:
		return 'asp_pallets';
	default:
		return '';
	}
}

export const getLoadSecReqENUM = (requirment: string): string => {
	switch (requirment) {
	case 'tension_belt':
		return LOAD_SEC_REQ.TENSION_BELTS;
	case 'edge_protect':
		return LOAD_SEC_REQ.EDGE_PROTECTION_SHIP_THIS_FREQUENTLY;
	case 'driver_assist_stop':
		return LOAD_SEC_REQ.DRIVER_ASSISTANCE_AT_STOP;
	case 'anti_slip_mat':
		return LOAD_SEC_REQ.ANTI_SLIP_MAT;
	case 'multilock':
		return LOAD_SEC_REQ.MULTILOCK;
	case 'sideboards':
		return LOAD_SEC_REQ.IS_SIDEBOARDS_FIVE_PER_FIELD;
	case 'chains':
		return LOAD_SEC_REQ.CHAINS;
	case 'stopbars':
		return LOAD_SEC_REQ.STOP_BARS;
	case 'cargo_net':
		return LOAD_SEC_REQ.CARGO_NET;
	case 'coil':
		return LOAD_SEC_REQ.COIL_SUPPORTS;
	case 'tir_cable':
		return LOAD_SEC_REQ.TIR_CABLE;
	case 'asp_pallets':
		return LOAD_SEC_REQ.ANGLES_STRAPS_PLY_FOR_ALL_PALLETS;
	default:
		return '';
	}
}

export const getLoadingSecurityRequirementLabel = (requirement: LOAD_SEC_REQ | string): string => {
	switch (requirement) {
	case LOAD_SEC_REQ.TENSION_BELTS:
		return 'Tension belts';
	case LOAD_SEC_REQ.EDGE_PROTECTION_SHIP_THIS_FREQUENTLY:
		return 'Edge protection ship this frequently';
	case LOAD_SEC_REQ.DRIVER_ASSISTANCE_AT_STOP:
		return 'Driver assistance at stop';
	case LOAD_SEC_REQ.ANTI_SLIP_MAT:
		return 'Anti slip mat';
	case LOAD_SEC_REQ.MULTILOCK:
		return 'Multilock';
	case LOAD_SEC_REQ.IS_SIDEBOARDS_FIVE_PER_FIELD:
		return 'Is Sideboards (5) Per field';
	case LOAD_SEC_REQ.CHAINS:
		return 'Chains';
	case LOAD_SEC_REQ.STOP_BARS:
		return 'Stop bars';
	case LOAD_SEC_REQ.CARGO_NET:
		return 'Cargo net';
	case LOAD_SEC_REQ.COIL_SUPPORTS:
		return 'Coil supports';
	case LOAD_SEC_REQ.TIR_CABLE:
		return 'TIR cable';
	case LOAD_SEC_REQ.ANGLES_STRAPS_PLY_FOR_ALL_PALLETS:
		return 'Angles, Straps, & Ply for all pallets';
	case LOAD_SEC_REQ.CORFLUTE:
		return 'Corflute';
	default:
		return 'Unknown'
	}
}

export enum CARGO_TYPE {
    PALLETS = 'PALLETS',
    FTL = 'FTL',
    FCL = 'FCL',
    LOOSE = 'LOOSE',
    // Note: VOLUME and WEIGHT are deprecated
    VOLUME = 'VOLUME',
    WEIGHT = 'WEIGHT',
    OTHER = 'OTHER'
}

export enum RATE_CARD_TYPE_PRICING {
    PALLETS = 'Pallets',
    WEIGHT = 'Weight',
    VOLUME = 'Volume',
    HOURS = 'Hours',
    DISTANCE = 'Distance',
    FULL_LOAD = 'FullLoad',
    CPK = 'CPK',
}

export enum CAPACITY_TYPE {
    FTL = 'FTL',
    PARTIAL = 'PARTIAL',
    VOLUMETRIC = 'VOLUMETRIC'
}

export const getCapacityTypeLabel = (capacityType: CAPACITY_TYPE): string => {
	switch (capacityType) {
	case CAPACITY_TYPE.VOLUMETRIC:
		return 'Other';
	case CAPACITY_TYPE.PARTIAL:
		return 'Less Than';
	case CAPACITY_TYPE.FTL:
	default:
		return 'Full Load';
	}
}

export const getCargoTypesLabel = (cargoType: CARGO_TYPE): string => {
	switch (cargoType) {
	case CARGO_TYPE.PALLETS:
		return 'Pallets';
	case CARGO_TYPE.LOOSE:
		return 'Loose';
	case CARGO_TYPE.OTHER:
		return 'Container';
	default:
		return cargoType;
	}
}

export enum CARGO_PICKER_TYPE {
	CAPACITY_DETAILS = 'capacity_details',
    CARGO_DETAILS = 'cargo_details',
    PALLET_OPTIONS = 'pallet_options',
    TRUCK_OPTIONS = 'truck_options',
    LOADING_OPTIONS = 'loading_options',
}

export enum CARGO_OPTIONS {
	NOT_DEFINED = 0,
	PALLETS = 1,
	FULL_TRUCK_LOAD = 2,
	VOLUMETRIC = 3,
	LOADING_METRES = 4
}

export enum TRUCK_OPTIONS {
	RIGID = 'Rigid',
	SINGLE = 'Single',
	A_DOUBLE = 'A-Double',
	B_DOUBLE = 'B-Double',
	B_TRIPLE = 'B-Triple',
	B_DOUBLE_HIGH_CUBE = 'B-Double High Cube',
	B_DOUBLE_SUPER_HIGH_CUBE = 'B-Double Super High Cube',
	FT20_CONTAINER = '20ft Container',
	FT40_CONTAINER = '40ft Container',
	FT20_Container_SERVER = 'Container (20FT Coastal)',
	FT40_Container_SERVER = 'Container (40FT Coastal)',
    SUPER_B_DOUBLE = 'Super B-Double',
    FT46_CONTAINER = 'Container (46FT Coastal)',
    FT48_CONTAINER = 'Container (48FT Coastal)',
}

export enum LOADING_OPTIONS {
	BACK = 'From back',
	SIDE = 'From side',
	TOP = 'From top',
	LIFTGATE = 'From liftgate'
}

export enum AVATAR_SIZE {
	LARGE = 'large',
	REGULAR = 'regular',
	SMALL = 'small',
	XSMALL = 'xsmall',
	XXSMALL = 'xxsmall',
	MICRO = 'micro'
}

export enum DATA_UPDATE_MODE {
	SERVER = 'server',
	LOCAL = 'local'
}

export enum DATA_SORT_ORDER {
	DESC = 'desc',
	ASC = 'asc'
}

export enum TABLE_ROW_DENSITY {
	COMPACT = 'compactRow',
	REGULAR = 'regularRow',
	EXPANDED = 'expandedRow'
}

export enum TIMESLOT_OPTIONS {
	NOT_REQUIRED = 'NOT_REQUIRED',
	OFLOAD_MUST_BOOK = 'OFLOAD_MUST_BOOK',
	BOOKED = 'BOOKED'
}

export enum POD_STATUS {
	POD_VERIFIED = 'POD Approved',
	POD_AUTO_APPROVED = 'POD Auto Approved',
	POD_REQUIRED = 'Awaiting POD',
	AWAITING_VERIFICATION = 'Awaiting Verification',
	POD_AWAITING_VERIFICATION = 'POD Awaiting Verification',
	POD_CHASING = 'Ofload Chasing',
	POD_IN_DISPUTE = 'In Dispute',
	POD_REJECTED = 'POD Rejected',
	POD_CHASED_AND_ABANDONED = 'Chased And Abandoned',
	POD_NOT_AVAILABLE = 'Carrier Advising Not Available',
	LOAD_NOT_DELIVERED = 'Load Not Delivered',
	POD_SHIPPER_CHASING = 'Shipper Chasing',
	POD_REJECTED_BY_SHIPPER = 'Rejected By Shipper',
	UNKNOWN = 'Unknown'
}

export const getCargoTypeLabel = (cargoType: CARGO_OPTIONS): string => {
	switch (cargoType) {
	case CARGO_OPTIONS.PALLETS:
		return 'AU Standard Pallet';
	case CARGO_OPTIONS.FULL_TRUCK_LOAD:
		return 'Truckload';
	case CARGO_OPTIONS.VOLUMETRIC:
		return 'Other';
	case CARGO_OPTIONS.LOADING_METRES:
		return 'Loading Metres';
	case CARGO_OPTIONS.NOT_DEFINED:
	default:
		return 'Not Defined';
	}
}

export const CARGO_TYPE_ID = {
	Pallets: 1,
	FullTruckload: 2,
	Volumetric: 3,
	LoadingMeters: 4,
	NotDefined: 0,
	Loose: 5,
	Other: 6,
}

export const JOB_STATUS = {
	UNALLOCATED: 0,
	IN_MARKETPLACE: 1,
	PREMIUM_LIST: 2,
	ALLOCATED: 3,
	CANCEL: 4,
}

export const QuoteFilterableStatus = [
	{ value: 'pending', label: 'Pending' },
	{ value: 'priced', label: 'Priced' },
	{ value: 'expired', label: 'Expired' },
	{ value: 'not_quoted', label: 'Not Quoted' }
]

export const TRANSPORT_SHIPMENT_FILTERABLE_STATUS = [
	'Allocated',
	'On The Road',
	'Delivered',
	'Awaiting POD Upload',
	'POD Awaiting Verification',
	'POD Rejected',
	'Shipper Invoice Generated',
	'Awaiting Carrier Invoice',
	'Shipper Invoiced',
	'Carrier Invoiced',
	'Overdue Shipper Payment',
	'Shipper Paid',
	'Carrier Paid',
	'Chasing POD',
	'In Dispute',
	'POD Chased And Abandoned',
	'No POD Available',
	'Shipper Payment Dispute',
	'Carrier Invoice Pending/Chasing',
	'No Carrier Invoice Provided',
];

export const INVOICE_FILTERABLE_STATUS = [
	{
		name: 'Shipper',
		expanded: true,
		options: [
			{
				label: 'Shipper Invoice Generated',
				value: 'shipper-invoice-generated'
			},
			{
				label: 'Shipper Invoiced',
				value: 'shipper-invoiced'
			},
			{
				label: 'Shipper Paid',
				value: 'shipper-paid'
			},
			{
				label: 'Shipper Payment Dispute',
				value: 'shipper-payment-dispute',
			},
			{
				label: 'Shipper Promised To Pay',
				value: 'shipper-promised-to-pay',
			},
			{
				label: 'Overdue Shipper Payment',
				value: 'shipper-payment-overdue'
			},
		]
	},
	{
		name: 'Carrier',
		expanded: true,
		options: [
			{
				label: 'Awaiting Carrier Invoice',
				value: 'carrier-awaiting-invoice'
			},
			{
				label: 'Carrier Invoiced',
				value: 'carrier-invoiced'
			},
			{
				label: 'Carrier Invoice Pending/Chasing',
				value: 'carrier-pending-or-chasing',
			},
			{
				label: 'Carrier Paid Via Butn',
				value: 'carrier-paid-butn'
			},
			{
				label: 'Carrier Paid Via RCTI',
				value: 'carrier-paid-rcti'
			},
			{
				label: 'Carrier Payment Dispute',
				value: 'carrier-payment-dispute',
			},
			{
				label: 'No Carrier Invoice Provided',
				value: 'carrier-no-invoice-provided',
			},
			{
				label: 'Do Not Pay',
				value: 'carrier-do-not-pay',
			},
		]
	},
]

export const ShipmentFilterableStatus = ['Pending', 'Allocated', 'On The Road', 'Delivered', 'Awaiting', 'Paid'];

export const SHIPMENT_FILTERABLE_STATUS = [
	'Allocated',
	'On The Road',
	'Delivered',
];

export enum TRUCK_SIZE {
    RIGID = 'RIGID',
    SINGLE = 'SINGLE',
	A_DOUBLE = 'A_DOUBLE',
    B_DOUBLE = 'B_DOUBLE',
    SKELETON = 'SKELETON',
    SIDELOADER = 'SIDELOADER',
    B_DOUBLE_HIGH_CUBE = 'B_DOUBLE_HIGH_CUBE',
    CONTAINER_20FT_COASTAL = 'CONTAINER_20FT_COASTAL',
    CONTAINER_40FT_COASTAL = 'CONTAINER_40FT_COASTAL',
    CONTAINER_46FT_COASTAL = 'CONTAINER_46FT_COASTAL',
    CONTAINER_48FT_COASTAL = 'CONTAINER_48FT_COASTAL',
    B_TRIPLE = 'B_TRIPLE',
    SUPER_B_DOUBLE = 'SUPER_B_DOUBLE',
}

export enum TRUCK_TYPE {
    ANY = 'ANY',
    TAUTLINER = 'TAUTLINER',
    PANTECH = 'PANTECH',
    FLAT_TOP = 'FLAT_TOP',
    TAUTLINER_STRAIGHT_DECK = 'TAUTLINER_STRAIGHT_DECK',
    TAUTLINER_DROP_DECK = 'TAUTLINER_DROP_DECK',
    TAUTLINER_MEZZANINE_DECK = 'TAUTLINER_MEZZANINE_DECK',
    SIDELOADER = 'SIDELOADER',
    SKELETON = 'SKELETON_TRAILER',
    OPEN_TOP = 'OPEN_TOP',
    UTE = 'UTE',
    TIPPER = 'TIPPER',
	SUPER_B_DOUBLE = 'SUPER_B_DOUBLE',
	B_TRIPLE = 'B_TRIPLE'
}

export enum FREIGHT_MODE {
    ROAD = 'ROAD',
    RAIL = 'RAIL',
    COASTAL = 'COASTAL',
}

export enum RATE_CARD_MODIFIERS {
	TRUCK1 = 'Tautliner',
	TRUCK2 = 'Pantech',
	TRUCK3 = 'Flat Top',
	TRUCK4 = 'Tautliner Straight Deck',
	TRUCK5 = 'Tautliner Drop Deck',
	TRUCK6 = 'Tautliner Mezzanine Deck',
	TRUCK7 = 'Sideloader',
	TRUCK8 = 'Skeleton Trailer',
	TRUCK9 = 'Open Top',
	TRUCK10 = 'Tipper',
}

export const COUNTRY_CODES = {
	AF: ['Afghanistan', 'AF', '93'],
	AL: ['Albania', 'AL', '355'],
	DZ: ['Algeria', 'DZ', '213'],
	AD: ['Andorra', 'AD', '376'],
	AO: ['Angola', 'AO', '244'],
	AG: ['Antigua and Barbuda', 'AG', '1268'],
	AR: ['Argentina', 'AR', '54', '(..) ........', 0],
	AM: ['Armenia', 'AM', '374', '.. ......'],
	AW: ['Aruba', 'AW', '297'],
	AU: ['Australia', 'AU', '61', '(..) .... ....'],
	AS: ['American Samoa', 'AS', '1'],
	AT: ['Austria', 'AT', '43'],
	AZ: ['Azerbaijan', 'AZ', '994', '(..) ... .. ..'],
	BS: ['Bahamas', 'BS', '1242'],
	BH: ['Bahrain', 'BH', '973'],
	BD: ['Bangladesh', 'BD', '880'],
	BB: ['Barbados', 'BB', '1246'],
	BY: ['Belarus', 'BY', '375', '(..) ... .. ..'],
	BE: ['Belgium', 'BE', '32', '... .. .. ..'],
	BZ: ['Belize', 'BZ', '501'],
	BJ: ['Benin', 'BJ', '229'],
	BT: ['Bhutan', 'BT', '975'],
	BO: ['Bolivia', 'BO', '591'],
	BA: ['Bosnia and Herzegovina', 'BA', '387'],
	BW: ['Botswana', 'BW', '267'],
	BR: ['Brazil', 'BR', '55', '(..) .........'],
	IO: ['British Indian Ocean Territory', 'IO', '246'],
	BN: ['Brunei', 'BN', '673'],
	BG: ['Bulgaria', 'BG', '359'],
	BF: ['Burkina Faso', 'BF', '226'],
	BI: ['Burundi', 'BI', '257'],
	KH: ['Cambodia', 'KH', '855'],
	CM: ['Cameroon', 'CM', '237'],
	CA: ['Canada', 'CA', '1', '(...) ...-....'],
	CV: ['Cape Verde', 'CV', '238'],
	BQ: ['Caribbean Netherlands', 'BQ', '599', '', 1],
	CF: ['Central African Republic', 'CF', '236'],
	TD: ['Chad', 'TD', '235'],
	CL: ['Chile', 'CL', '56'],
	CN: ['China', 'CN', '86', '..-.........'],
	CO: ['Colombia', 'CO', '57', '... ... ....'],
	KM: ['Comoros', 'KM', '269'],
	CD: ['Congo', 'CD', '243'],
	CG: ['Congo', 'CG', '242'],
	CR: ['Costa Rica', 'CR', '506', '....-....'],
	CI: ['Côte d’Ivoire', 'CI', '225', '.. .. .. ..'],
	HR: ['Croatia', 'HR', '385'],
	CU: ['Cuba', 'CU', '53'],
	CW: ['Curaçao', 'CW', '599', '', 0],
	CY: ['Cyprus', 'CY', '357', '.. ......'],
	CZ: ['Czech Republic', 'CZ', '420', '... ... ...'],
	DK: ['Denmark', 'DK', '45', '.. .. .. ..'],
	DJ: ['Djibouti', 'DJ', '253'],
	DM: ['Dominica', 'DM', '1767'],
	DO: ['Dominican Republic', 'DO', '1', ''],
	EC: ['Ecuador', 'EC', '593'],
	EG: ['Egypt', 'EG', '20'],
	SV: ['El Salvador', 'SV', '503', '....-....'],
	GQ: ['Equatorial Guinea', 'GQ', '240'],
	ER: ['Eritrea', 'ER', '291'],
	EE: ['Estonia', 'EE', '372', '.... ......'],
	ET: ['Ethiopia', 'ET', '251'],
	FJ: ['Fiji', 'FJ', '679'],
	FI: ['Finland', 'FI', '358', '.. ... .. ..'],
	FR: ['France', 'FR', '33', '. .. .. .. ..'],
	GF: ['French Guiana', 'GF', '594'],
	PF: ['French Polynesia', 'PF', '689'],
	GA: ['Gabon', 'GA', '241'],
	GM: ['Gambia', 'GM', '220'],
	GE: ['Georgia', 'GE', '995'],
	DE: ['Germany', 'DE', '49', '.... ........'],
	GH: ['Ghana', 'GH', '233'],
	GR: ['Greece', 'GR', '30'],
	GD: ['Grenada', 'GD', '1473'],
	GP: ['Guadeloupe', 'GP', '590', '', 0],
	GU: ['Guam', 'GU', '1671'],
	GT: ['Guatemala', 'GT', '502', '....-....'],
	GN: ['Guinea', 'GN', '224'],
	GW: ['Guinea-Bissau', 'GW', '245'],
	GY: ['Guyana', 'GY', '592'],
	HT: ['Haiti', 'HT', '509', '....-....'],
	HN: ['Honduras', 'HN', '504'],
	HK: ['Hong Kong', 'HK', '852', '.... ....'],
	HU: ['Hungary', 'HU', '36'],
	IS: ['Iceland', 'IS', '354', '... ....'],
	IN: ['India', 'IN', '91', '.....-.....'],
	ID: ['Indonesia', 'ID', '62'],
	IR: ['Iran', 'IR', '98', '... ... ....'],
	IQ: ['Iraq', 'IQ', '964'],
	IE: ['Ireland', 'IE', '353', '.. .......'],
	IL: ['Israel', 'IL', '972', '... ... ....'],
	IT: ['Italy', 'IT', '39', '... .......', 0],
	JM: ['Jamaica', 'JM', '1876'],
	JP: ['Japan', 'JP', '81', '.. .... ....'],
	JO: ['Jordan', 'JO', '962'],
	KZ: ['Kazakhstan', 'KZ', '7', '... ...-..-..'],
	KE: ['Kenya', 'KE', '254'],
	KI: ['Kiribati', 'KI', '686'],
	XK: ['Kosovo', 'XK', '383'],
	KW: ['Kuwait', 'KW', '965'],
	KG: ['Kyrgyzstan', 'KG', '996', '... ... ...'],
	LA: ['Laos', 'LA', '856'],
	LV: ['Latvia', 'LV', '371', '.. ... ...'],
	LB: ['Lebanon', 'LB', '961'],
	LS: ['Lesotho', 'LS', '266'],
	LR: ['Liberia', 'LR', '231'],
	LY: ['Libya', 'LY', '218'],
	LI: ['Liechtenstein', 'LI', '423'],
	LT: ['Lithuania', 'LT', '370'],
	LU: ['Luxembourg', 'LU', '352'],
	MO: ['Macau', 'MO', '853'],
	MK: ['Macedonia', 'MK', '389'],
	MG: ['Madagascar', 'MG', '261'],
	MW: ['Malawi', 'MW', '265'],
	MY: ['Malaysia', 'MY', '60', '..-....-....'],
	MV: ['Maldives', 'MV', '960'],
	ML: ['Mali', 'ML', '223'],
	MT: ['Malta', 'MT', '356'],
	MH: ['Marshall Islands', 'MH', '692'],
	MQ: ['Martinique', 'MQ', '596'],
	MR: ['Mauritania', 'MR', '222'],
	MU: ['Mauritius', 'MU', '230'],
	MX: ['Mexico', 'MX', '52', '... ... ....'],
	FM: ['Micronesia', 'FM', '691'],
	MD: ['Moldova', 'MD', '373', '(..) ..-..-..'],
	MC: ['Monaco', 'MC', '377'],
	MN: ['Mongolia', 'MN', '976'],
	ME: ['Montenegro', 'ME', '382'],
	MA: ['Morocco', 'MA', '212'],
	MZ: ['Mozambique', 'MZ', '258'],
	MM: ['Myanmar', 'MM', '95'],
	NA: ['Namibia', 'NA', '264'],
	NR: ['Nauru', 'NR', '674'],
	NP: ['Nepal', 'NP', '977'],
	NL: ['Netherlands', 'NL', '31', '.. ........'],
	NC: ['New Caledonia', 'NC', '687'],
	NZ: ['New Zealand', 'NZ', '64', '...-...-....'],
	NI: ['Nicaragua', 'NI', '505'],
	NE: ['Niger', 'NE', '227'],
	NG: ['Nigeria', 'NG', '234'],
	KP: ['North Korea', 'KP', '850'],
	NO: ['Norway', 'NO', '47', '... .. ...'],
	OM: ['Oman', 'OM', '968'],
	PK: ['Pakistan', 'PK', '92', '...-.......'],
	PW: ['Palau', 'PW', '680'],
	PS: ['Palestine', 'PS', '970'],
	PA: ['Panama', 'PA', '507'],
	PG: ['Papua New Guinea', 'PG', '675'],
	PY: ['Paraguay', 'PY', '595'],
	PE: ['Peru', 'PE', '51'],
	PH: ['Philippines', 'PH', '63', '.... .......'],
	PL: ['Poland', 'PL', '48', '...-...-...'],
	PT: ['Portugal', 'PT', '351'],
	PR: ['Puerto Rico', 'PR', '1', ''],
	QA: ['Qatar', 'QA', '974'],
	RE: ['Réunion', 'RE', '262'],
	RO: ['Romania', 'RO', '40'],
	RU: ['Russia', 'RU', '7', '(...) ...-..-..', 0],
	RW: ['Rwanda', 'RW', '250'],
	KN: ['Saint Kitts and Nevis', 'KN', '1869'],
	LC: ['Saint Lucia', 'LC', '1758'],
	VC: ['Saint Vincent and the Grenadines', 'VC', '1784'],
	WS: ['Samoa', 'WS', '685'],
	SM: ['San Marino', 'SM', '378'],
	ST: ['São Tomé and Príncipe', 'ST', '239'],
	SA: ['Saudi Arabia', 'SA', '966'],
	SN: ['Senegal', 'SN', '221'],
	RS: ['Serbia', 'RS', '381'],
	SC: ['Seychelles', 'SC', '248'],
	SL: ['Sierra Leone', 'SL', '232'],
	SG: ['Singapore', 'SG', '65', '....-....'],
	SK: ['Slovakia', 'SK', '421'],
	SI: ['Slovenia', 'SI', '386'],
	SB: ['Solomon Islands', 'SB', '677'],
	SO: ['Somalia', 'SO', '252'],
	ZA: ['South Africa', 'ZA', '27'],
	KR: ['South Korea', 'KR', '82', '... .... ....'],
	SS: ['South Sudan', 'SS', '211'],
	ES: ['Spain', 'ES', '34', '... ... ...'],
	LK: ['Sri Lanka', 'LK', '94'],
	SD: ['Sudan', 'SD', '249'],
	SR: ['Suriname', 'SR', '597'],
	SZ: ['Swaziland', 'SZ', '268'],
	SE: ['Sweden', 'SE', '46', '(...) ...-...'],
	CH: ['Switzerland', 'CH', '41', '.. ... .. ..'],
	SY: ['Syria', 'SY', '963'],
	TW: ['Taiwan', 'TW', '886'],
	TJ: ['Tajikistan', 'TJ', '992'],
	TZ: ['Tanzania', 'TZ', '255'],
	TH: ['Thailand', 'TH', '66'],
	TL: ['Timor-Leste', 'TL', '670'],
	TG: ['Togo', 'TG', '228'],
	TO: ['Tonga', 'TO', '676'],
	TT: ['Trinidad and Tobago', 'TT', '1868'],
	TN: ['Tunisia', 'TN', '216'],
	TR: ['Turkey', 'TR', '90', '... ... .. ..'],
	TM: ['Turkmenistan', 'TM', '993'],
	TV: ['Tuvalu', 'TV', '688'],
	UG: ['Uganda', 'UG', '256'],
	UA: ['Ukraine', 'UA', '380', '(..) ... .. ..'],
	AE: ['United Arab Emirates', 'AE', '971'],
	GB: ['United Kingdom', 'GB', '44', '.... ......'],
	US: ['United States', 'US', '1', '(...) ...-....'],
	UY: ['Uruguay', 'UY', '598'],
	UZ: ['Uzbekistan', 'UZ', '998', '.. ... .. ..'],
	VU: ['Vanuatu', 'VU', '678'],
	VA: ['Vatican City', 'VA', '39', '.. .... ....', 1],
	VE: ['Venezuela', 'VE', '58'],
	VN: ['Vietnam', 'VN', '84'],
	YE: ['Yemen', 'YE', '967'],
	ZM: ['Zambia', 'ZM', '260'],
	ZW: ['Zimbabwe', 'ZW', '263'],
};

export const TIMEZONE_OPTS = [
	{
		value: 'Pacific/Midway',
		label: '(GMT-11:00) Midway Island, Samoa',
		offset: -11,
		abbrev: 'SST',
		altName: 'Samoa Standard Time',
		id: 0,
	},
	{
		value: 'Pacific/Honolulu',
		label: '(GMT-10:00) Hawaii',
		offset: -10,
		abbrev: 'HAST',
		altName: 'Hawaii-Aleutian Standard Time',
		id: 1,
	},
	{
		value: 'America/Juneau',
		label: '(GMT-8:00) Alaska',
		offset: -8,
		abbrev: 'AKDT',
		altName: 'Alaska Daylight Time',
		id: 2,
	},
	{
		value: 'America/Dawson',
		label: '(GMT-7:00) Dawson, Yukon',
		offset: -7,
		abbrev: 'MST',
		altName: 'Mountain Standard Time',
		id: 3,
	},
	{
		value: 'America/Phoenix',
		label: '(GMT-7:00) Arizona',
		offset: -7,
		abbrev: 'MST',
		altName: 'Mountain Standard Time',
		id: 4,
	},
	{
		value: 'America/Tijuana',
		label: '(GMT-7:00) Tijuana',
		offset: -7,
		abbrev: 'PDT',
		altName: 'Pacific Daylight Time',
		id: 5,
	},
	{
		value: 'America/Los_Angeles',
		label: '(GMT-7:00) Pacific Time',
		offset: -7,
		abbrev: 'PDT',
		altName: 'Pacific Daylight Time',
		id: 6,
	},
	{
		value: 'America/Boise',
		label: '(GMT-6:00) Mountain Time',
		offset: -6,
		abbrev: 'MDT',
		altName: 'Mountain Daylight Time',
		id: 7,
	},
	{
		value: 'America/Chihuahua',
		label: '(GMT-6:00) Chihuahua, La Paz, Mazatlan',
		offset: -6,
		abbrev: 'HNPMX',
		altName: 'Mexican Pacific Standard Time',
		id: 8,
	},
	{
		value: 'America/Regina',
		label: '(GMT-6:00) Saskatchewan',
		offset: -6,
		abbrev: 'CST',
		altName: 'Central Standard Time',
		id: 9,
	},
	{
		value: 'America/Mexico_City',
		label: '(GMT-6:00) Guadalajara, Mexico City, Monterrey',
		offset: -6,
		abbrev: 'CST',
		altName: 'Central Mexico Standard Time',
		id: 10,
	},
	{
		value: 'America/Belize',
		label: '(GMT-6:00) Central America',
		offset: -6,
		abbrev: 'CST',
		altName: 'Central Standard Time',
		id: 11,
	},
	{
		value: 'America/Chicago',
		label: '(GMT-5:00) Central Time',
		offset: -5,
		abbrev: 'CDT',
		altName: 'Central Daylight Time',
		id: 12,
	},
	{
		value: 'America/Bogota',
		label: '(GMT-5:00) Bogota, Lima, Quito',
		offset: -5,
		abbrev: 'COT',
		altName: 'Colombia Standard Time',
		id: 13,
	},
	{
		value: 'America/Detroit',
		label: '(GMT-4:00) Eastern Time',
		offset: -4,
		abbrev: 'EDT',
		altName: 'Eastern Daylight Time',
		id: 14,
	},
	{
		value: 'America/Caracas',
		label: '(GMT-4:00) Caracas, La Paz',
		offset: -4,
		abbrev: 'VET',
		altName: 'Venezuela Standard Time',
		id: 15,
	},
	{
		value: 'America/Santiago',
		label: '(GMT-4:00) Santiago',
		offset: -4,
		abbrev: 'CLT',
		altName: 'Chile Standard Time',
		id: 16,
	},
	{
		value: 'America/Sao_Paulo',
		label: '(GMT-3:00) Brasilia',
		offset: -3,
		abbrev: 'BRT',
		altName: 'Brasilia Standard Time',
		id: 17,
	},
	{
		value: 'America/Montevideo',
		label: '(GMT-3:00) Montevideo',
		offset: -3,
		abbrev: 'UYT',
		altName: 'Uruguay Standard Time',
		id: 18,
	},
	{
		value: 'America/Argentina/Buenos_Aires',
		label: '(GMT-3:00) Buenos Aires, Georgetown',
		offset: -3,
		abbrev: 'ART',
		altName: 'Argentina Standard Time',
		id: 19,
	},
	{
		value: 'America/St_Johns',
		label: '(GMT-2:30) Newfoundland and Labrador',
		offset: -2.5,
		abbrev: 'HETN',
		altName: 'Newfoundland Daylight Time',
		id: 20,
	},
	{
		value: 'America/Godthab',
		label: '(GMT-2:00) Greenland',
		offset: -2,
		abbrev: 'WGST',
		altName: 'West Greenland Summer Time',
		id: 21,
	},
	{
		value: 'Atlantic/Cape_Verde',
		label: '(GMT-1:00) Cape Verde Islands',
		offset: -1,
		abbrev: 'CVT',
		altName: 'Cape Verde Standard Time',
		id: 22,
	},
	{
		value: 'Atlantic/Azores',
		label: '(GMT+0:00) Azores',
		offset: 0,
		abbrev: 'AZOST',
		altName: 'Azores Summer Time',
		id: 23,
	},
	{
		value: 'Etc/GMT',
		label: '(GMT+0:00) UTC',
		offset: 0,
		abbrev: 'GMT',
		altName: 'British Standard Time',
		id: 24,
	},
	{
		value: 'Europe/London',
		label: '(GMT+1:00) Edinburgh, London',
		offset: 1,
		abbrev: 'BST',
		altName: 'British Summer Time',
		id: 25,
	},
	{
		value: 'Europe/Dublin',
		label: '(GMT+1:00) Dublin',
		offset: 1,
		abbrev: 'IST',
		altName: 'Irish Standard Time',
		id: 26,
	},
	{
		value: 'Europe/Lisbon',
		label: '(GMT+1:00) Lisbon',
		offset: 1,
		abbrev: 'WEST',
		altName: 'Western European Summer Time',
		id: 27,
	},
	{
		value: 'Africa/Casablanca',
		label: '(GMT+1:00) Casablanca, Monrovia',
		offset: 1,
		abbrev: 'WEST',
		altName: 'Western European Summer Time',
		id: 28,
	},
	{
		value: 'Atlantic/Canary',
		label: '(GMT+1:00) Canary Islands',
		offset: 1,
		abbrev: 'WEST',
		altName: 'Western European Summer Time',
		id: 29,
	},
	{
		value: 'Africa/Algiers',
		label: '(GMT+1:00) West Central Africa',
		offset: 1,
		abbrev: 'CET',
		altName: 'Central European Standard Time',
		id: 30,
	},
	{
		value: 'Europe/Belgrade',
		label: '(GMT+2:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		offset: 2,
		abbrev: 'CEST',
		altName: 'Central European Summer Time',
		id: 31,
	},
	{
		value: 'Europe/Sarajevo',
		label: '(GMT+2:00) Sarajevo, Skopje, Warsaw, Zagreb',
		offset: 2,
		abbrev: 'CEST',
		altName: 'Central European Summer Time',
		id: 32,
	},
	{
		value: 'Europe/Brussels',
		label: '(GMT+2:00) Brussels, Copenhagen, Madrid, Paris',
		offset: 2,
		abbrev: 'CEST',
		altName: 'Central European Summer Time',
		id: 33,
	},
	{
		value: 'Europe/Amsterdam',
		label: '(GMT+2:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		offset: 2,
		abbrev: 'CEST',
		altName: 'Central European Summer Time',
		id: 34,
	},
	{
		value: 'Africa/Cairo',
		label: '(GMT+2:00) Cairo',
		offset: 2,
		abbrev: 'EET',
		altName: 'Eastern European Standard Time',
		id: 35,
	},
	{
		value: 'Africa/Harare',
		label: '(GMT+2:00) Harare, Pretoria',
		offset: 2,
		abbrev: 'CAT',
		altName: 'Central Africa Standard Time',
		id: 36,
	},
	{
		value: 'Europe/Bucharest',
		label: '(GMT+3:00) Bucharest',
		offset: 3,
		abbrev: 'EEST',
		altName: 'Eastern European Summer Time',
		id: 37,
	},
	{
		value: 'Europe/Helsinki',
		label: '(GMT+3:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
		offset: 3,
		abbrev: 'EEST',
		altName: 'Eastern European Summer Time',
		id: 38,
	},
	{
		value: 'Europe/Athens',
		label: '(GMT+3:00) Athens',
		offset: 3,
		abbrev: 'EEST',
		altName: 'Eastern European Summer Time',
		id: 39,
	},
	{
		value: 'Asia/Jerusalem',
		label: '(GMT+3:00) Jerusalem',
		offset: 3,
		abbrev: 'IDT',
		altName: 'Israel Daylight Time',
		id: 40,
	},
	{
		value: 'Europe/Moscow',
		label: '(GMT+3:00) Istanbul, Minsk, Moscow, St. Petersburg, Volgograd',
		offset: 3,
		abbrev: 'MSK',
		altName: 'Moscow Standard Time',
		id: 41,
	},
	{
		value: 'Asia/Kuwait',
		label: '(GMT+3:00) Kuwait, Riyadh',
		offset: 3,
		abbrev: 'AST',
		altName: 'Arabian Standard Time',
		id: 42,
	},
	{
		value: 'Africa/Nairobi',
		label: '(GMT+3:00) Nairobi',
		offset: 3,
		abbrev: 'EAT',
		altName: 'East Africa Standard Time',
		id: 43,
	},
	{
		value: 'Asia/Baghdad',
		label: '(GMT+3:00) Baghdad',
		offset: 3,
		abbrev: 'AST',
		altName: 'Arabian Standard Time',
		id: 44,
	},
	{
		value: 'Asia/Tehran',
		label: '(GMT+3:30) Tehran',
		offset: 3.5,
		abbrev: 'IRST',
		altName: 'Iran Standard Time',
		id: 45,
	},
	{
		value: 'Asia/Dubai',
		label: '(GMT+4:00) Abu Dhabi, Muscat',
		offset: 4,
		abbrev: 'GST',
		altName: 'Gulf Standard Time',
		id: 46,
	},
	{
		value: 'Asia/Baku',
		label: '(GMT+4:00) Baku, Tbilisi, Yerevan',
		offset: 4,
		abbrev: 'AZT',
		altName: 'Azerbaijan Standard Time',
		id: 47,
	},
	{
		value: 'Asia/Kabul',
		label: '(GMT+4:30) Kabul',
		offset: 4.5,
		abbrev: 'AFT',
		altName: 'Afghanistan Standard Time',
		id: 48,
	},
	{
		value: 'Asia/Yekaterinburg',
		label: '(GMT+5:00) Ekaterinburg',
		offset: 5,
		abbrev: 'YEKT',
		altName: 'Yekaterinburg Standard Time',
		id: 49,
	},
	{
		value: 'Asia/Karachi',
		label: '(GMT+5:00) Islamabad, Karachi, Tashkent',
		offset: 5,
		abbrev: 'PKT',
		altName: 'Pakistan Standard Time',
		id: 50,
	},
	{
		value: 'Asia/Kolkata',
		label: '(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi',
		offset: 5.5,
		abbrev: 'IST',
		altName: 'India Standard Time',
		id: 51,
	},
	{
		value: 'Asia/Colombo',
		label: '(GMT+5:30) Sri Jayawardenepura',
		offset: 5.5,
		abbrev: 'IST',
		altName: 'India Standard Time',
		id: 52,
	},
	{
		value: 'Asia/Kathmandu',
		label: '(GMT+5:45) Kathmandu',
		offset: 5.75,
		abbrev: 'NPT',
		altName: 'Nepal Standard Time',
		id: 53,
	},
	{
		value: 'Asia/Dhaka',
		label: '(GMT+6:00) Astana, Dhaka',
		offset: 6,
		abbrev: 'BST',
		altName: 'Bangladesh Standard Time',
		id: 54,
	},
	{
		value: 'Asia/Almaty',
		label: '(GMT+6:00) Almaty, Novosibirsk',
		offset: 6,
		abbrev: 'ALMT',
		altName: 'East Kazakhstan Standard Time',
		id: 55,
	},
	{
		value: 'Asia/Rangoon',
		label: '(GMT+6:30) Yangon Rangoon',
		offset: 6.5,
		abbrev: 'MMT',
		altName: 'Myanmar Standard Time',
		id: 56,
	},
	{
		value: 'Asia/Bangkok',
		label: '(GMT+7:00) Bangkok, Hanoi, Jakarta',
		offset: 7,
		abbrev: 'ICT',
		altName: 'Indochina Standard Time',
		id: 57,
	},
	{
		value: 'Asia/Krasnoyarsk',
		label: '(GMT+7:00) Krasnoyarsk',
		offset: 7,
		abbrev: 'KRAT',
		altName: 'Krasnoyarsk Standard Time',
		id: 58,
	},
	{
		value: 'Asia/Shanghai',
		label: '(GMT+8:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
		offset: 8,
		abbrev: 'CST',
		altName: 'China Standard Time',
		id: 59,
	},
	{
		value: 'Asia/Kuala_Lumpur',
		label: '(GMT+8:00) Kuala Lumpur, Singapore',
		offset: 8,
		abbrev: 'MYT',
		altName: 'Malaysia Standard Time',
		id: 60,
	},
	{
		value: 'Asia/Taipei',
		label: '(GMT+8:00) Taipei',
		offset: 8,
		abbrev: 'CST',
		altName: 'Taipei Standard Time',
		id: 61,
	},
	{
		value: 'Australia/Perth',
		label: '(GMT+8:00) Perth',
		offset: 8,
		abbrev: 'AWST',
		altName: 'Western Australia Standard Time',
		id: 62,
	},
	{
		value: 'Asia/Irkutsk',
		label: '(GMT+8:00) Irkutsk, Ulaanbaatar',
		offset: 8,
		abbrev: 'IRKT',
		altName: 'Irkutsk Standard Time',
		id: 63,
	},
	{
		value: 'Asia/Seoul',
		label: '(GMT+9:00) Seoul',
		offset: 9,
		abbrev: 'KST',
		altName: 'Korean Standard Time',
		id: 64,
	},
	{
		value: 'Asia/Tokyo',
		label: '(GMT+9:00) Osaka, Sapporo, Tokyo',
		offset: 9,
		abbrev: 'JST',
		altName: 'Japan Standard Time',
		id: 65,
	},
	{
		value: 'Asia/Yakutsk',
		label: '(GMT+9:00) Yakutsk',
		offset: 9,
		abbrev: 'YAKT',
		altName: 'Yakutsk Standard Time',
		id: 66,
	},
	{
		value: 'Australia/Darwin',
		label: '(GMT+9:30) Darwin',
		offset: 9.5,
		abbrev: 'ACST',
		altName: 'Australian Central Standard Time',
		id: 67,
	},
	{
		value: 'Australia/Adelaide',
		label: '(GMT+9:30) Adelaide',
		offset: 9.5,
		abbrev: 'ACST',
		altName: 'Central Australia Standard Time',
		id: 68,
	},
	{
		value: 'Australia/Sydney',
		label: '(GMT+10:00) Canberra, Melbourne, Sydney',
		offset: 10,
		abbrev: 'AEST',
		altName: 'Eastern Australia Standard Time',
		id: 69,
	},
	{
		value: 'Australia/Brisbane',
		label: '(GMT+10:00) Brisbane',
		offset: 10,
		abbrev: 'AEST',
		altName: 'Brisbane Standard Time',
		id: 70,
	},
	{
		value: 'Australia/Hobart',
		label: '(GMT+10:00) Hobart',
		offset: 10,
		abbrev: 'AEST',
		altName: 'Eastern Australia Standard Time',
		id: 71,
	},
	{
		value: 'Asia/Vladivostok',
		label: '(GMT+10:00) Vladivostok',
		offset: 10,
		abbrev: 'VLAT',
		altName: 'Vladivostok Standard Time',
		id: 72,
	},
	{
		value: 'Pacific/Guam',
		label: '(GMT+10:00) Guam, Port Moresby',
		offset: 10,
		abbrev: 'ChST',
		altName: 'Chamorro Standard Time',
		id: 73,
	},
	{
		value: 'Asia/Magadan',
		label: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia',
		offset: 11,
		abbrev: 'MAGT',
		altName: 'Magadan Standard Time',
		id: 74,
	},
	{
		value: 'Asia/Kamchatka',
		label: '(GMT+12:00) Kamchatka, Marshall Islands',
		offset: 12,
		abbrev: 'PETT',
		altName: 'Petropavlovsk-Kamchatski Standard Time',
		id: 75,
	},
	{
		value: 'Pacific/Fiji',
		label: '(GMT+12:00) Fiji Islands',
		offset: 12,
		abbrev: 'FJT',
		altName: 'Fiji Standard Time',
		id: 76,
	},
	{
		value: 'Pacific/Auckland',
		label: '(GMT+12:00) Auckland, Wellington',
		offset: 12,
		abbrev: 'NZST',
		altName: 'New Zealand Standard Time',
		id: 77,
	},
	{
		value: 'Pacific/Tongatapu',
		label: "(GMT+13:00) Nuku'alofa",
		offset: 13,
		abbrev: 'TOT',
		altName: 'Tonga Standard Time',
		id: 78,
	},
];

export const DAYS = [
	{
		value: 0,
		label: 'Sunday',
	},
	{
		value: 1,
		label: 'Monday',
	},
	{
		value: 2,
		label: 'Tuesday',
	},
	{
		value: 3,
		label: 'Wednesday',
	},
	{
		value: 4,
		label: 'Thursday',
	},
	{
		value: 5,
		label: 'Friday',
	},
	{
		value: 6,
		label: 'Saturday',
	},
];
export const MONTH_NAMES = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]

export const InvoiceStatus = {
	Rejected: 'Rejected',
	Approved: 'Approved',
	UnderInvestigation: 'Under Investigation',
	Pending: 'Pending',
	Review: 'Review'
}

export enum NONRCTIVIEW {
	APPROVE = 'APPROVE',
	REJECT = 'REJECT',
	REVIEW = 'REVIEW',
	ALL = 'ALL'
}

export enum NON_RCTI_STATUS_ACTION {
	APPROVE = 'approve',
	REJECT = 'reject',
	REVIEW = 'review',
}

export enum SHIPPER_EXTRA_CARE_MODES {
	GO_LIVE = 'go_live',
	HYPER_CARE = 'hyper_care'
}
export const getShipperCareLabel = (mode: SHIPPER_EXTRA_CARE_MODES|string|null): string => {
	switch (mode) {
	case SHIPPER_EXTRA_CARE_MODES.HYPER_CARE:
		return 'Hyper Care';
	case SHIPPER_EXTRA_CARE_MODES.GO_LIVE:
		return 'Go Live';
	default:
		return ''
	}
}
export enum SHIPPER_LABEL_TYPE {
	HEADING = 'heading',
	LINK = 'link'
}

export enum TruckSizes {
    RIGID = TRUCK_OPTIONS.RIGID,
    SINGLE = TRUCK_OPTIONS.SINGLE,
	A_DOUBLE = TRUCK_OPTIONS.A_DOUBLE,
    B_DOUBLE = TRUCK_OPTIONS.B_DOUBLE,
    B_DOUBLE_HIGH_CUBE = TRUCK_OPTIONS.B_DOUBLE_HIGH_CUBE,
    CONTAINER_20_FT_COASTAL = TRUCK_OPTIONS.FT20_CONTAINER,
    CONTAINER_40_FT_COASTAL = TRUCK_OPTIONS.FT40_CONTAINER,
    CONTAINER_46_FT_COASTAL = '46ft Container',
    CONTAINER_48_FT_COASTAL = '48ft Container',
    SUPER_B_DOUBLE = TRUCK_OPTIONS.SUPER_B_DOUBLE,
    B_TRIPLE = TRUCK_OPTIONS.B_TRIPLE,
}

export enum ACCESS_LEVELS {
    ADMIN = 100,
    FINANCE = 200,
    MANAGER = 300,
    OPERATIONS = 400,
    TEAM = 500,
    BOOKING_MANAGER = 550,
    GUEST = 600,
}

export enum TIMESLOT_STATUS_ENUM {
	NOT_REQUIRED = 'NOT_REQUIRED',
	OFLOAD_MUST_BOOK = 'OFLOAD_MUST_BOOK',
	BOOKED = 'BOOKED'
}

export const timeSlotStatuses = [
	{
		value: TIMESLOT_STATUS_ENUM.NOT_REQUIRED,
		label: 'No time slot required. Arrive any time within opening hours.'
	}, {
		value: TIMESLOT_STATUS_ENUM.OFLOAD_MUST_BOOK,
		label: 'Ofload to book time slot directly with receiver.'
	}, {
		value: TIMESLOT_STATUS_ENUM.BOOKED,
		label: 'Time slot is booked and is mandatory.'
	}
];

import { PropsWithChildren } from 'react';
import styles from './Drag.module.scss';
import {
	Container as DndContainer,
	Draggable,
	ContainerOptions
} from 'react-smooth-dnd';
import DragIcon from '@mui/icons-material/DragIndicator';
import { TStyles } from '@types';

type TDrag = {
	ghosted?: boolean;
	handlePos?: 'left' | 'right' | 'top' | 'bottom' | null;
	backgroundColor?: string;
	borderWidth?: number;
	borderColor?: string;
	stretch?: boolean;
	dragDisabled?: boolean;
}
interface TContainer extends ContainerOptions {
	direction?: 'column' | 'row';
	gap?: string;
}

const DragCore = () => {
	return <></>;
}

const Container = (props:PropsWithChildren<TContainer>) => {
	const {
		onDrop,
		behaviour,
		onDragStart,
		onDragEnd,
		dragClass,
		getChildPayload,
		nonDragAreaSelector,
		dragHandleSelector,
		removeOnDropOut,
		groupName,
		shouldAcceptDrop,
		getGhostParent,
		lockAxis,
		orientation,
		direction = 'column',
		gap = '8px',
		dropPlaceholder,
		onDragEnter,
		onDragLeave,
		children
	} = props;
	const setStyle = () => {
		const styles:TStyles = {}
		styles['display'] = 'flex';
		styles['flexDirection'] = direction;
		styles['gap'] = gap;
		return styles;
	}
	return (
		<DndContainer
			onDrop={onDrop}
			behaviour={behaviour}
			onDragStart={onDragStart}
			onDragEnd={onDragEnd}
			getChildPayload={getChildPayload}
			dragClass={dragClass}
			style={setStyle()}
			nonDragAreaSelector={nonDragAreaSelector}
			dragHandleSelector={dragHandleSelector}
			removeOnDropOut={removeOnDropOut}
			groupName={groupName}
			getGhostParent={getGhostParent}
			shouldAcceptDrop={shouldAcceptDrop}
			lockAxis={lockAxis}
			orientation={orientation}
			dropPlaceholder={dropPlaceholder}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}
		>
			{children}
		</DndContainer>
	)
}

const Item = (props:PropsWithChildren<TDrag>) => {
	const {
		ghosted,
		handlePos = 'top',
		backgroundColor = 'var(--neutral-00)',
		borderWidth = 1,
		borderColor = 'var(--neutral-03)',
		stretch = true,
		children,
		dragDisabled
	} = props;
	const setStyles = () => {
		const styles:TStyles = {};
		if (backgroundColor) styles['backgroundColor'] = backgroundColor;
		if (stretch) {
			styles['display'] = 'flex';
		} else {
			styles['display'] = 'inline-flex';
		}
		styles['border'] = `${borderWidth}px solid ${borderColor}`;
		return styles;
	}

	const dragHandleDisabled = dragDisabled ? styles.dragHandleDisabled : '';

	return (
		<Draggable className={`${ghosted ? 'disableDrag' : ''} ${dragDisabled ? 'disableDrag' : ''}`}>
			<div className={`${styles.draggable} ${ghosted ? styles.ghosted : ''} ${ghosted ? 'disableDrag' : ''}`} style={setStyles()}>
				{handlePos === 'top' && <DragIcon className={`${styles.dragIcon} ${styles.dragIconTB} dragHandle ${dragHandleDisabled}`} />}
				{(handlePos === 'left' || handlePos === 'right') && <div className={styles.row}>
					{handlePos === 'left' && <DragIcon className={`${styles.dragIcon} dragHandle ${dragHandleDisabled}`} style={{ marginRight: '8px' }} />}
					<div style={{ flex: 1 }}>
						{children}
					</div>
					{handlePos === 'right' && <DragIcon className={`${styles.dragIcon} dragHandle ${dragHandleDisabled}`} style={{ marginLeft: '8px' }} />}
				</div>}
				{(handlePos !== 'left' && handlePos !== 'right') && children}
				{handlePos === 'bottom' && <DragIcon className={`${styles.dragIcon} ${styles.dragIconTB} dragHandle ${dragHandleDisabled}`} />}
			</div>
		</Draggable>
	);
}

DragCore.Container = Container;
DragCore.Item = Item;
export const Drag = DragCore;

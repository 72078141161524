export const formatNumber = (
	value = 0,
	{ locale = 'en-AU', maximumFractionDigits = 2, suffix = '', returnEmptyForZero = false, prefix = '', minimumFractionDigits = 0 } = {}
) => {
	if (returnEmptyForZero && !value) {
		return '';
	}
	const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits, minimumFractionDigits });
	const formattedNumber = formatter.format(value);
	return `${prefix}${formattedNumber}${suffix}`;
};
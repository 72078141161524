import { useState } from 'react';
import { VALIDATION_ERROR, TValidationConnectObj } from '@types';
import { z } from 'zod';

type TValidation = {
	[n:string]: any;
}

type TOptions = {
	connectHasError?: boolean;
	connectErrorMessage?: boolean;
	refine?: {
		validator: (d:any) => any;
		options?: {
			message?: string;
			path?: (string | number)[];
			params?: object;
		};
	};
	superRefine?: (d:any, ctx:any) => any;
} | undefined;

export const useValidation = (validations:TValidation, options?:TOptions) => {
	const [formErrors, setFormErrors] = useState<VALIDATION_ERROR[]>([]);
	let clonedErrors = [...formErrors];

	const schema = z.object(validations);
	const hasErrorMsg = (name:string) => {
		let realName = name;
		if (name.indexOf('.') >= 0) {
			const nameParts = name.split('.');
			realName = nameParts[nameParts.length - 1];
		}
		return formErrors.findIndex((err:VALIDATION_ERROR) => err.field === realName) >= 0;
	}
	const getErrorMsg = (name:string) => {
		let realName = name;
		if (name.indexOf('.') >= 0) {
			const nameParts = name.split('.');
			realName = nameParts[nameParts.length - 1];
		}
		const [errorMessage] = formErrors.filter((err:VALIDATION_ERROR) => err.field === realName);
		return errorMessage?.issues?.join(', ');
	}
	const formValid = (theForm:any, renderCheck = false) => {
		let parse;
		if (options?.refine) {
			parse = schema.refine(options.refine.validator, options.refine.options ?? {}).safeParse(theForm);
		} else if (options?.superRefine) {
			parse = schema.superRefine(options.superRefine).safeParse(theForm);
		} else {
			parse = schema.safeParse(theForm);
		}
		const errors:VALIDATION_ERROR[] = [];
		if (!parse.success) {
			const { issues } = parse.error;
			for (let i = 0, l = issues.length; i < l; ++i) {
				const issue = issues[i];
				const newErr: VALIDATION_ERROR = {
					field: '',
					issues: [],
					path: issue.path.join('.'),
				};

				if (issue.code === 'invalid_union') {
					for (const unionIssue of issue.unionErrors) {
						for (const subIssue of unionIssue.issues) {
							const subErr: VALIDATION_ERROR = {
								field: '',
								issues: [],
								path: subIssue.path.join('.'),
							};
							if (Array.isArray(subIssue.path)) {
								subErr.field = subIssue.path[subIssue.path.length - 1];
							} else {
								subErr.field = subIssue.path as string | number;
							}
							if (subErr.issues) subErr.issues.push(subIssue.message);
							errors.push(subErr);
						}
					}
				} else {
					if (Array.isArray(issue.path)) {
						newErr.field = issue.path[issue.path.length - 1];
					} else {
						newErr.field = issue.path as unknown as string | number;
					}
					if (newErr.issues) newErr.issues.push(issue.message);
					errors.push(newErr);
				}
			}
			if (!renderCheck) {
				setFormErrors(errors);
			}
		}
		return {
			success: parse.success,
			errors
		};
	}

	const updateValidationErrors = (errors:VALIDATION_ERROR, clear:boolean = false) => {
		if (clear) {
			clonedErrors = clonedErrors.filter((err) => {
				return err.field !== errors.field;
			});
		} else {
			clonedErrors.push(errors);
		}
		setFormErrors(clonedErrors);
	}

	const updateBulkValidationErrors = (errors: VALIDATION_ERROR[]) => {
		const cloned = [...new Set([...errors, ...formErrors])];
		setFormErrors(cloned);
	}

	const clearErrors = () => setFormErrors([]);

	const deepSearch:any = (obj:any, part:string[]) => {
		const isArr = obj[part[0]]?.element || obj?.element;
		const isObj = obj[part[0]]?.shape || obj?.shape;
		if (isArr) {
			if (obj?.element) {
				return deepSearch(obj?.element, part);
			}
			if (obj[part[0]]?.element) {
				return deepSearch(obj[part[0]].element, part);
			}
			const [curPart] = part;
			part.shift();
			if (obj[curPart].element.shape) {
				if (part.length > 1) {
					return deepSearch(obj[curPart].element.shape, part);
				}
				return obj[curPart].element.shape[part[0]];
			}
			if (part.length > 0) {
				return deepSearch(obj[curPart].element, part);
			}
			return obj[curPart].element;
		}
		if (isObj) {
			const [curPart] = part;
			part.shift();
			if (part.length > 0) {
				if (obj?.shape) {
					return deepSearch(obj?.shape, part);
				}
				return deepSearch(obj[curPart].shape, part);
			}
			return obj[curPart].shape[part[0]];
		}
		return obj[part[0]];
	}

	const connect = (name: string) => {
		let validationObj;
		if (name.indexOf('.') >= 0) {
			const nameParts = name.split('.');
			validationObj = deepSearch(validations, nameParts);
		} else {
			validationObj = validations[name];
		}
		const returnObj:TValidationConnectObj = {
			validations: validationObj,
			onValidationError: updateValidationErrors,
			onValidationSuccess: updateValidationErrors
		}
		if (options) {
			if (options.connectHasError === undefined || options.connectHasError) {
				returnObj.hasError = hasErrorMsg(name);
				returnObj.errorMessage = getErrorMsg(name);
			}
		} else {
			returnObj.hasError = hasErrorMsg(name);
			returnObj.errorMessage = getErrorMsg(name);
		}
		return returnObj;
	}

	const hasErrorMsgPath = (path: string) => {
		return formErrors.findIndex(
			(err: VALIDATION_ERROR) => err.path === path) >= 0;
	};

	const getErrorMsgPath = (path: string) => {
		const errorMessage = formErrors.find(
			(err: VALIDATION_ERROR) => err.path === path);
		return errorMessage?.issues?.join(', ');
	};

	const updateValidationErrorsPath = (path: string) => {
		return (errors: VALIDATION_ERROR, clear: boolean = false) => {
			let clonedErrors = [...formErrors];
			const errorsWithPath = {
				...errors,
				path
			};
			if (clear) {
				clonedErrors = clonedErrors.filter(
					(err) => err.path !== errorsWithPath.path);
			} else {
				clonedErrors.push(errorsWithPath);
			}
			setFormErrors(clonedErrors);
		};
	};

	const deepPathSearch = (obj: any, parts: string[]) => {
		if (parts.length === 0) return obj;

		const [currentPart, ...restParts] = parts;

		if (Array.isArray(obj)) {
			const index = parseInt(currentPart, 10);
			if (!isNaN(index)) {
				return deepSearch(obj[index], restParts);
			}
		} else if (obj && typeof obj === 'object') {
			return deepSearch(obj[currentPart], restParts);
		}

		return null;
	};

	const connectPath = (path: string) => {
		const nameParts = path.split('.');
		const validationObj = deepPathSearch(validations, nameParts);

		const returnObj: TValidationConnectObj = {
			validations: validationObj,
			onValidationError: updateValidationErrorsPath(path),
			onValidationSuccess: updateValidationErrorsPath(path),
			hasError: hasErrorMsgPath(path),
			errorMessage: getErrorMsgPath(path),
		};

		return returnObj;
	};

	return {
		formErrors: formErrors as VALIDATION_ERROR[],
		formValid,
		connect,
		connectPath,
		updateValidationErrors,
		updateBulkValidationErrors,
		clearErrors
	} as const;
}

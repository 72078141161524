import { useEffect, useState, useCallback, ChangeEvent } from 'react';
import styles from './AddressPicker.module.scss';
import { TypeAhead } from '@components';
import { debounce } from 'lodash';
import { useGetAutocomplete, useGetSavedAddresses } from '@api';
import { TypeAheadProps } from '@components/FORM/TypeAhead/TypeAhead';
import { AJAXError, TGroupedOpts, TypeAheadOption } from '@types';
import { AxiosError } from 'axios';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';

interface TProps extends TypeAheadProps {
	locationType?: 'REGION' | 'COMPLETE_ADDRESS' | 'POSTCODE',
	onFetchError?: (e:Error | AxiosError | AJAXError | null) => void;
	overrideUrl?: string;
	fetchSavedAddresses?: boolean;
}

export const AddressPicker = (props:TProps) => {
	const {
		name,
		locationType,
		id,
		value,
		onChange,
		onSelected,
		onFetchError,
		options: extOpts = null,
		overrideUrl,
		fetchSavedAddresses = false,
		...rest
	} = props;
	const {
		loadingAutocomplete,
		addresses,
		getAutocompleteError,
		callGetAutocomplete
	} = useGetAutocomplete();
	const {
		loadingSavedAddresses,
		savedAddresses,
		callGetSavedAddresses
	} = useGetSavedAddresses();
	const [realOptions, setRealOptions] = useState<TGroupedOpts | null>(null);
	const [options, setOptions] = useState<TGroupedOpts | null>(null);
	const [savedOpts, setSavedOpts] = useState<TGroupedOpts | null>(null);
	const search = useCallback(
		debounce((search:string) => {
			callGetAutocomplete(search, locationType);
		}, 500),
		[]);
	const getSavedAddresses = useCallback(
		debounce((search: string) => {
			callGetSavedAddresses({ params: { search } });
		}, 500),
		[]);
	const updateValue = (e:ChangeEvent<HTMLInputElement>) => {
		onChange(e);
		if (e && e.target.value !== '') {
			search(e.target.value);
			if (fetchSavedAddresses) getSavedAddresses(e.target.value);
		}
	}
	const updateSelected = (v:TypeAheadOption | string, p?:{ group: string }) => {
		const newProps = p;
		if (newProps && newProps.group) {
			if (newProps.group === 'Saved Addresses') newProps.group = 'SAVED_ADDRESS';
			if (newProps.group === 'Complete Address') newProps.group = 'COMPLETE_ADDRESS';
			if (newProps.group === 'Postcode') newProps.group = 'POSTCODE';
			if (newProps.group === 'Region') newProps.group = 'REGION';
		}
		onSelected(v, newProps);
	}

	useEffect(() => {
		if (addresses && addresses.data) {
			const { suggestions } = addresses.data;
			const newOpts:TGroupedOpts = {}
			if ((suggestions.COMPLETE_ADDRESS && suggestions.COMPLETE_ADDRESS.length && !locationType) || (suggestions.COMPLETE_ADDRESS && suggestions.COMPLETE_ADDRESS.length && locationType === 'COMPLETE_ADDRESS')) {
				newOpts['Complete Address'] = suggestions.COMPLETE_ADDRESS.map((addr) => {
					return {
						label: <div className={styles.addressRow}>
							<SearchIcon />
							{addr}
						</div>,
						value: addr
					}
				});
			}
			if ((suggestions.POSTCODE && suggestions.POSTCODE.length && !locationType) || (suggestions.POSTCODE && suggestions.POSTCODE.length && locationType === 'POSTCODE')) {
				newOpts['Postcode'] = suggestions.POSTCODE.map((addr) => {
					return {
						label: <div className={styles.addressRow}>
							<SearchIcon />
							{addr}
						</div>,
						value: addr
					}
				});
			}
			if ((suggestions.REGION && suggestions.REGION.length && !locationType) || (suggestions.REGION && suggestions.REGION.length && locationType === 'REGION')) {
				newOpts['Region'] = suggestions.REGION.map((addr) => {
					return {
						label: <div className={styles.addressRow}>
							<SearchIcon />
							{addr}
						</div>,
						value: addr
					}
				});
			}
			setOptions(newOpts);
		}
	}, [addresses]);
	useEffect(() => {
		if (getAutocompleteError) {
			if (onFetchError) onFetchError(getAutocompleteError)
		}
	}, [getAutocompleteError]);
	useEffect(() => {
		if (value === '') {
			setOptions(null);
		}
	}, [value]);
	useEffect(() => {
		if (extOpts) setOptions(extOpts as TGroupedOpts);
	}, [extOpts]);

	useEffect(() => {
		if (savedAddresses) {
			const newSavedOpts: TGroupedOpts = {
				'Saved Addresses': savedAddresses.map((addr) => {
					return {
						label: <div className={styles.addressRow}>
							<LocationIcon />
							<div>
								<p>{addr.name}</p>
								<p>{addr.geo_address.street_address}</p>
							</div>
						</div>,
						value: addr
					}
				})
			}
			setSavedOpts(newSavedOpts);
		}
	}, [savedAddresses]);

	useEffect(() => {
		let newOpts: TGroupedOpts = {};
		newOpts = { ...(savedOpts ? savedOpts : {}), ...(options ? options : {}) }
		const hasOpts = savedOpts || options;
		setRealOptions(hasOpts ? newOpts : null);
	}, [
		options,
		savedOpts
	]);

	return (
		<TypeAhead
			{...rest}
			name={name}
			id={id}
			value={value}
			onChange={(e:ChangeEvent<HTMLInputElement>) => { updateValue(e) }}
			onSelected={(v:TypeAheadOption | string, p?:{ group: string }) => { updateSelected(v, p) }}
			options={realOptions}
			isLoadingOptions={loadingAutocomplete || loadingSavedAddresses}
		/>
	);
}

import { TRUCK_SIZE, TRUCK_TYPE } from '@types';

export const getTruckSizeLabel = (truckSize: string): string => {
	const labels = {
		[TRUCK_SIZE.RIGID]: 'Rigid',
		[TRUCK_SIZE.SINGLE]: 'Single',
		[TRUCK_SIZE.A_DOUBLE]: 'A-Double',
		[TRUCK_SIZE.B_DOUBLE]: 'B-Double',
		[TRUCK_SIZE.SKELETON]: 'Skeleton',
		[TRUCK_SIZE.SIDELOADER]: 'Sideloader',
		[TRUCK_SIZE.B_DOUBLE_HIGH_CUBE]: 'B-Double High Cube',
		[TRUCK_SIZE.CONTAINER_20FT_COASTAL]: 'Container (20FT Coastal)',
		[TRUCK_SIZE.CONTAINER_40FT_COASTAL]: 'Container (40FT Coastal)',
		[TRUCK_SIZE.CONTAINER_46FT_COASTAL]: 'Container (46FT Coastal)',
		[TRUCK_SIZE.CONTAINER_48FT_COASTAL]: 'Container (48FT Coastal)',
		[TRUCK_SIZE.B_TRIPLE]: 'B-Triple',
		[TRUCK_SIZE.SUPER_B_DOUBLE]: 'Super B-Double',
	};
	return labels[truckSize as keyof typeof TRUCK_SIZE];
}

export const getTruckSizeENUMVal = (truckSize: string): string => {
	const enums = {
		[TRUCK_SIZE.RIGID]: 'RIGID',
		[TRUCK_SIZE.SINGLE]: 'SINGLE',
		[TRUCK_SIZE.A_DOUBLE]: 'A_DOUBLE',
		[TRUCK_SIZE.B_DOUBLE]: 'B_DOUBLE',
		[TRUCK_SIZE.SKELETON]: 'SKELETON',
		[TRUCK_SIZE.SIDELOADER]: 'SIDELOADER',
		[TRUCK_SIZE.B_DOUBLE_HIGH_CUBE]: 'B_DOUBLE_HIGH_CUBE',
		[TRUCK_SIZE.CONTAINER_20FT_COASTAL]: 'CONTAINER_20FT_COASTAL',
		[TRUCK_SIZE.CONTAINER_40FT_COASTAL]: 'CONTAINER_40FT_COASTAL',
		[TRUCK_SIZE.CONTAINER_46FT_COASTAL]: 'CONTAINER_46FT_COASTAL',
		[TRUCK_SIZE.CONTAINER_48FT_COASTAL]: 'CONTAINER_48FT_COASTAL',
		[TRUCK_SIZE.B_TRIPLE]: 'B_TRIPLE',
		[TRUCK_SIZE.SUPER_B_DOUBLE]: 'SUPER_B_DOUBLE',
	}
	return enums[truckSize as keyof typeof TRUCK_SIZE];
}

export const getTruckTypeLabel = (truckType: string): string => {
	switch (truckType) {
	case TRUCK_TYPE.ANY:
		return 'Any Truck';
	case TRUCK_TYPE.TAUTLINER:
		return 'Tautliner';
	case TRUCK_TYPE.PANTECH:
		return 'Pantech';
	case TRUCK_TYPE.FLAT_TOP:
		return 'Flat Top';
	case TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK:
		return 'Tautliner Straight Deck';
	case TRUCK_TYPE.TAUTLINER_DROP_DECK:
		return 'Tautliner Drop Deck';
	case TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK:
		return 'Tautliner Mezzanine Deck';
	case TRUCK_TYPE.SIDELOADER:
		return 'Sideloader';
	case TRUCK_TYPE.SKELETON:
		return 'Skeleton';
	case TRUCK_TYPE.OPEN_TOP:
		return 'Open Top';
	case TRUCK_TYPE.UTE:
		return 'Ute';
	case TRUCK_TYPE.TIPPER:
		return 'Tipper';
	default:
		return ''
	}
}

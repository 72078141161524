import {
	useState,
	useEffect,
	PropsWithChildren,
	SetStateAction,
	Dispatch
} from 'react';
import { usePageTitle } from '@hooks';
import { PageTitleContext } from './Context';

export type TContext = {
	title: string;
	route?: string;
	writable?: boolean;
	update?: Dispatch<SetStateAction<TContext | null>>;
}

type TProps = {
	title: string;
}

export const PageTitleProvider = (props:PropsWithChildren) => {
	const { children } = props;
	const [contextVal, setContextVal] = useState<TContext | null>(null);

	useEffect(() => {
		setContextVal({
			title: 'Ofload',
			route: '',
			writable: true,
			update: setContextVal
		});
	}, []);

	return <PageTitleContext.Provider value={contextVal}>
		{children}
	</PageTitleContext.Provider>
}

export const PageTitle = (props: TProps) => {
	const { title } = props;
	const { set } = usePageTitle();
	set(title, true, false);
	return null;
}

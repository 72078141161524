import styles from './StatusChip.module.css';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoneIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CHIP_TYPE, CHIP_ICON_POS, TOOLTIP_POSITION, CHIP_SIZE } from '@types';
import { Tooltip } from '../Tooltip';
import { Caption, Text } from '@components';
interface ChipProps {
	text: string;
	type?: CHIP_TYPE;
	showIcon?: boolean;
	CustomIcon?: React.ComponentType<{ [key: string]: string }>;
	customIconColor?: string;
	iconPos?: CHIP_ICON_POS;
	iconOnly?: boolean;
	rounded?: boolean;
	collapse?: boolean;
	size?: CHIP_SIZE;
	stretch?: boolean;
	centerText?: boolean;
}

export const StatusChip = (props: ChipProps) => {
	const {
		text,
		type = CHIP_TYPE.INFO,
		showIcon = false,
		CustomIcon = null,
		customIconColor = '',
		iconPos = CHIP_ICON_POS.LEFT,
		iconOnly = false,
		rounded = false,
		collapse = false,
		size = CHIP_SIZE.LARGE,
		stretch = false,
		centerText = false
	} = props;
	const getTypeClass = () => {
		switch (type) {
		case CHIP_TYPE.ERROR:
			return styles.error;
		case CHIP_TYPE.SUCCESS:
			return styles.success;
		case CHIP_TYPE.WARN:
			return styles.warn;
		case CHIP_TYPE.NEUTRAL:
			return styles.neutral;
		case CHIP_TYPE.NEW:
			return styles.new;
		default:
			return styles.info;
		}
	}
	const getSizeClass = () => {
		switch (size) {
		case CHIP_SIZE.SMALL:
			return styles.small;
		case CHIP_SIZE.MEDIUM:
			return styles.medium;
		case CHIP_SIZE.LARGE:
		default:
			return;
		}
	}
	const renderIcon = () => {
		if (CustomIcon !== null) {
			return <CustomIcon data-testid={'@InfoChip-CustomIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} color={customIconColor} />
		}
		switch (type) {
		case CHIP_TYPE.ERROR:
			return <ErrorIcon data-testid={'@InfoChip-ErrorIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		case CHIP_TYPE.SUCCESS:
			return <DoneIcon data-testid={'@InfoChip-SuccessIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		case CHIP_TYPE.WARN:
			return <WarningIcon data-testid={'@InfoChip-WarnIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		case CHIP_TYPE.NEUTRAL:
			return <></>;
		case CHIP_TYPE.NEW:
			return <></>;
		default:
			return <InfoIcon data-testid={'@InfoChip-InfoIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		}
	}
	const renderText = () => {
		if(size === CHIP_SIZE.SMALL) {
			return <Caption>{text}</Caption>
		}
		 return <Text>{text}</Text>
	}
	const buildStyles = () => {
		const css: { [key: string]: any } = {};
		css['display'] = 'flex';
		css['width'] = 'fit-content';
		if (stretch) {
			css['flex'] = 1;
			css['width'] = '100%';
		}
		return css;
	}
	return (
		<>
			{iconOnly &&
				<Tooltip position={TOOLTIP_POSITION.TOP} text={text}>
					<div data-testid={'@InfoChip-IconOnly'} className={`${styles.iconOnlyContainer} ${getSizeClass()} ${getTypeClass()}`}>
						{renderIcon()}
					</div>
				</Tooltip>
			}
			{!iconOnly && <div style={buildStyles()}>
				<div data-testid={'@InfoChip'} className={`${styles.container} ${getTypeClass()} ${getSizeClass()} ${rounded ? styles.isRounded : ''} ${!showIcon ? styles.noIcon : ''} ${collapse ? styles.collapse : ''} ${stretch ? styles.stretch : ''} ${centerText && styles.centerText}`}>
					{showIcon && iconPos === CHIP_ICON_POS.LEFT && <>
						{renderIcon()}
						{renderText()}

					</>}
					{showIcon && iconPos === CHIP_ICON_POS.RIGHT && <>
						{renderText()}
						{renderIcon()}
					</>}
					{!showIcon && 
						renderText()
					}
				</div>
			</div>}
		</>
	);
}

export const InfoChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.INFO}
			showIcon
			customIconColor={'var(--func-blue-dark)'}
			{...props}
		/>
	)
}

export const NeutralChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.NEUTRAL}
			showIcon
			customIconColor={'var(--chip-neutral)'}
			{...props}
		/>
	)
}

export const SuccessChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.SUCCESS}
			showIcon
			customIconColor={'var(--txt-success)'}
			{...props}
		/>
	)
}

export const WarnChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.WARN}
			showIcon
			customIconColor={'var(--txt-warning)'}
			{...props}
		/>
	)
}

export const ErrorChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.ERROR}
			showIcon
			customIconColor={'var(--func-red-dark)'}
			{...props}
		/>
	)
}

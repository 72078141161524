import React, {
	useRef,
	useState,
	useEffect,
	ChangeEvent,
	FocusEvent,
	ClipboardEvent,
	ElementType,
	ReactElement
} from 'react';
import styles from './InputField.module.scss';
import { INPUT_TYPE, INPUT_ICON_POS, VALIDATION_ERROR, INPUT_SIZE } from '@types';
import { Spacer, Caption } from '@components';
import ClearIcon from '@mui/icons-material/Clear';
import { ZodTypeAny } from 'zod';

export interface InputFieldProps extends React.ComponentPropsWithoutRef<'input'> {
	value: string;
	onChange: (e:ChangeEvent<HTMLInputElement>) => void;
	type?: INPUT_TYPE;
	disabled?: boolean;
	Icon?: ElementType;
	iconPos?: INPUT_ICON_POS;
	label?: string;
	charCount?: boolean;
	charCountLimit?: number;
	onFocus?: (e:FocusEvent<HTMLInputElement>) => void;
	onBlur?: (e:FocusEvent<HTMLInputElement>) => void;
	placeholder?: string;
	required?: boolean;
	displayThin?: boolean;
	inputRef?: any;
	stretch?: boolean;
	name: string;
	validations?: ZodTypeAny;
	validateAsNumber?: boolean;
	hasError?: boolean;
	errorMessage?: string | JSX.Element;
	onValidationError?: (error:VALIDATION_ERROR, clear:boolean) => void;
	onValidationSuccess?: (success:VALIDATION_ERROR, clear: boolean) => void;
	keepErrorSpace?: boolean;
	onPaste?: (e:ClipboardEvent<HTMLInputElement>) => void;
	onClearInput?: (v: string) => void;
	collapse?: boolean;
	width?: string;
	leftModule?: ReactElement<any>;
	rightModule?: ReactElement<any>;
	iconSize?: number;
	inputSize?: INPUT_SIZE;
	inline?: boolean
}

export const TextInput = (props:InputFieldProps) => {
	const {
		value,
		onChange,
		type = INPUT_TYPE.TEXT,
		disabled = false,
		Icon = null,
		iconPos = INPUT_ICON_POS.RIGHT,
		label,
		inputRef,
		charCount = false,
		charCountLimit = 524288, // This is the legit default and max length a HTML input field can have...
		onFocus,
		onBlur,
		placeholder,
		required = false,
		displayThin = false,
		stretch = false,
		name,
		validations,
		validateAsNumber,
		hasError,
		errorMessage,
		onValidationError,
		onValidationSuccess,
		keepErrorSpace = true,
		onPaste,
		onClearInput,
		collapse,
		width = '250px',
		leftModule,
		rightModule,
		iconSize = 18,
		inputSize = INPUT_SIZE.REGULAR,
		inline = false,
		...otherProps
	} = props;
	const fieldRef = useRef<HTMLInputElement>(null);
	const [isCollapsed, setIsCollapsed] = useState(collapse);

	const fieldType = () => {
		switch (type) {
		case INPUT_TYPE.NUMBER:
			return 'number';
		case INPUT_TYPE.EMAIL:
			return 'email';
		case INPUT_TYPE.PHONE:
			return 'tel';
		case INPUT_TYPE.PASSWORD:
			return 'password';
		default:
			return 'text';
		}
	}
	const onFieldFocus = (e:FocusEvent<HTMLInputElement>) => {
		if (onFocus) onFocus(e);
		if (collapse !== undefined) {
			setIsCollapsed(false);
		}
	}
	const onFieldBlur = (e:FocusEvent<HTMLInputElement>) => {
		if (onBlur) onBlur(e);
		if (validations) {
			const valid = validations.safeParse(validateAsNumber && value !== '' ? parseInt(value) : value);
			if (!valid.success) {
				const issues = [];
				for (let i=0, l=valid.error.issues.length; i<l; ++i) {
					issues.push(valid.error.issues[i].message);
				}
				const error = {
					field: name,
					issues
				};
				if (onValidationError) {
					onValidationError(error, false);
				}
			} else if (onValidationSuccess) {
				onValidationSuccess({ field: name }, true);
			}
		}
		if (collapse) setIsCollapsed(collapse);
	}
	const hasValue = () => {
		return value !== '' && value !== null && value !== undefined;
	}
	const setStyles = () => {
		const style:{[key:string]:string} = {};
		if (collapse !== undefined && !isCollapsed) {
			style['width'] = width;
		}
		return style;
	}
	const containerClick = () => {
		if (fieldRef.current && isCollapsed) fieldRef.current.focus();
	}

	useEffect(() => {
		if (collapse !== undefined) setIsCollapsed(collapse);
	}, [collapse]);

	return (
		<div data-testid={'@Ofload-InputContainer'} className={`${styles.column} ${stretch ? styles.flex : ''}`}>
			<div className={`${inline ? styles.inline: ""}`}>
				{(label || charCount) && <div className={styles.labelsContainer}>
					{label && <label className={styles.label}>{label}{required ? <span className={styles.required}> *</span> : <></>}</label>}
					{charCount && <p className={styles.charCount}>{charCountLimit > 0 ? `${value.length}/${charCountLimit}` : value.length}</p>}
				</div>}
				<div data-testid={'@Ofload-InputInnerContainer'} className={`${styles.container} ${disabled ? styles.disabled : ''} ${hasError ? styles.error : ''}`}  style={setStyles()} onClick={containerClick}>
					{leftModule}
					<div className={`${styles.innerContainer} ${inputSize===INPUT_SIZE.SMALL ? styles.small : ""} ${displayThin ? styles.displayThin : ''} ${isCollapsed ? styles.collapsed : ''}`}>
						{Icon !== null && iconPos === INPUT_ICON_POS.LEFT && <Icon data-testid={'@Ofload-InputIcon-Left'} className={`${styles.icon} ${styles.iconLeft} ${disabled ? styles.disabledIcon : undefined}`} style={{ fontSize: `${iconSize}px` }} />}
						<input data-testid={'@Ofload-TextBox'} ref={inputRef ?? fieldRef} disabled={disabled} type={fieldType()} value={value || ''} onChange={onChange} maxLength={charCountLimit} onFocus={onFieldFocus} onBlur={onFieldBlur} placeholder={placeholder} name={name} onPaste={onPaste} {...otherProps} />
						{onClearInput && hasValue() && <button onClick={() => onClearInput('')} className={styles.clearButton} aria-label={'Clear Input'}>
							<ClearIcon className={styles.clearIcon}  />
						</button>}
						{Icon !== null && iconPos === INPUT_ICON_POS.RIGHT && <Icon data-testid={'@Ofload-InputIcon-Right'} className={`${styles.icon} ${styles.iconRight} ${disabled ? styles.disabledIcon : undefined}`} style={{ fontSize: `${iconSize}px` }} />}
					</div>
					{rightModule}
				</div>
			</div>
			{hasError && <div className={styles.errorMsgContainer}>
				<Spacer size={5} />
				<Caption color={'var(--txt-error)'}>
					{errorMessage}
				</Caption>
			</div>}
			{keepErrorSpace && !hasError && <div className={styles.errorMsgContainer} />}
		</div>
	);
}

export const InputField = TextInput;
export const NumberField = (props:InputFieldProps) => {
	return <TextInput type={INPUT_TYPE.NUMBER} {...props} />
}
export const EmailField = (props:InputFieldProps) => {
	return <TextInput type={INPUT_TYPE.EMAIL} {...props} />
}
export const PasswordField = (props:InputFieldProps) => {
	return <TextInput type={INPUT_TYPE.PASSWORD} {...props} />
}

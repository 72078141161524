export const capitalizeFirstLetter = (text: string, allWords = false) => {
	if (!allWords) {
		const words = text.split(' ');
		return words.map((word, i) => {
			if (i === 0) return `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`;
			return word.toLowerCase();
		}).join(' ');
	} else {
		const words = text.split(' ');
		return words.map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`).join(' ');
	}
}
export enum LOCATION_TYPE {
	WAREHOUSE = 'WAREHOUSE',
	DISTRIBUTION_CENTRE = 'DISTRIBUTION_CENTRE',
	CROSS_DOCK = 'CROSS_DOCK',
	STORE = 'STORE',
}

export const getLocationTypeLabel = (locationType: LOCATION_TYPE): string => {
	switch (locationType) {
	case LOCATION_TYPE.WAREHOUSE:
		return 'Warehouse';
	case LOCATION_TYPE.DISTRIBUTION_CENTRE:
		return 'Distribution Centre';
	case LOCATION_TYPE.CROSS_DOCK:
		return 'Cross dock';
	case LOCATION_TYPE.STORE:
		return 'Store';
	default:
		return '';
	}
}

export enum DangerousGoodType {
	FLAMMABLE_SOLIDS = 'FLAMMABLE_SOLIDS',
	GASSES = 'GASSES',
	TOXIC_SUBSTANCES = 'TOXIC_SUBSTANCES',
	CORROSIVE_SUBSTANCES = 'CORROSIVE_SUBSTANCES',
	OXIDISING_AGENTS = 'OXIDISING_AGENTS',
	RADIOACTIVE_MATERIAL = 'RADIOACTIVE_MATERIAL',
	EXPLOSIVES = 'EXPLOSIVES',
	HAZARDOUS = 'HAZARDOUS',
	MISCELLANEOUS_DG = 'MISCELLANEOUS_DG'
}

export const getDangerousGoodTypeLabel = (dangerousGoodType: DangerousGoodType): string => {
	switch (dangerousGoodType) {
	case DangerousGoodType.FLAMMABLE_SOLIDS:
		return 'Flammable Solids';
	case DangerousGoodType.GASSES:
		return 'Gasses';
	case DangerousGoodType.TOXIC_SUBSTANCES:
		return 'Toxic Substances';
	case DangerousGoodType.CORROSIVE_SUBSTANCES:
		return 'Corrosive Substances';
	case DangerousGoodType.OXIDISING_AGENTS:
		return 'Oxidising Agents';
	case DangerousGoodType.RADIOACTIVE_MATERIAL:
		return 'Radioactive Material';
	case DangerousGoodType.EXPLOSIVES:
		return 'Explosives';
	case DangerousGoodType.HAZARDOUS:
		return 'Environmentally Hazardous Substances';
	case DangerousGoodType.MISCELLANEOUS_DG:
		return 'Miscellaneous Dangerous Goods';
	default:
		return 'Unknown';
	}
}
export enum PPEOption {
	SAFETY_SHOES = 'SAFETY_SHOES',
	SAFETY_GLASSES = 'SAFETY_GLASSES',
	HIGH_VISIBILITY_CLOTHING = 'HIGH_VISIBILITY_CLOTHING',
	LONG_SLEEVES = 'LONG_SLEEVES',
	HARD_HATS = 'HARD_HATS',
	HAZARD_SPILL_KITS = 'HAZARD_SPILL_KITS',
	LONG_PANTS = 'LONG_PANTS'
}


export const getPPEOptionLabel = (ppeOption: PPEOption): string => {
	switch (ppeOption) {
	case PPEOption.SAFETY_SHOES:
		return 'Safety Shoes/Boots';
	case PPEOption.SAFETY_GLASSES:
		return 'Safety Glasses/Goggles';
	case PPEOption.HIGH_VISIBILITY_CLOTHING:
		return 'High-Visibility Clothing';
	case PPEOption.LONG_SLEEVES:
		return 'Protective high visibility Sleeves';
	case PPEOption.LONG_PANTS:
		return 'Protective Trousers or Leg Protection';
	case PPEOption.HARD_HATS:
		return 'Hard Hats';
	case PPEOption.HAZARD_SPILL_KITS:
		return 'Spill Response Kits or Chemical Spill Kits';
	default:
		return 'Unknown';
	}
}
export enum LoadingOptions {
	REAR_LOAD = 'REAR_LOAD',
	SIDE_LOAD = 'SIDE_LOAD',
	TOP_LOAD = 'TOP_LOAD',
	HAND_LOAD = 'HAND_LOAD',
	TAIL_GATE = 'TAIL_GATE',
	LOADING_EQUIPMENT = 'LOADING_EQUIPMENT'
}

export type LoadingOptionsKeys = keyof typeof LoadingOptions;

export const getLoadingOptionsLabel = (option: LoadingOptions | string): string => {
	switch (option) {
	case LoadingOptions.REAR_LOAD:
		return 'Rear load available?';
	case LoadingOptions.SIDE_LOAD:
		return 'Side load available?';
	case LoadingOptions.TOP_LOAD:
		return 'Top load available?';
	case LoadingOptions.HAND_LOAD:
		return 'Hand load/unload';
	case LoadingOptions.TAIL_GATE:
		return 'Tail gate needed?';
	case LoadingOptions.LOADING_EQUIPMENT:
		return 'Loading equipment available on site?';
	default:
		return 'Unknown';
	}
}
export enum LoadingSecurityRequirement {
	TENSION_BELTS = 'TENSION_BELTS',
	EDGE_PROTECTION_SHIP_THIS_FREQUENTLY = 'EDGE_PROTECTION_SHIP_THIS_FREQUENTLY',
	DRIVER_ASSISTANCE_AT_STOP = 'DRIVER_ASSISTANCE_AT_STOP',
	ANTI_SLIP_MAT = 'ANTI_SLIP_MAT',
	MULTILOCK = 'MULTILOCK',
	IS_SIDEBOARDS_FIVE_PER_FIELD = 'IS_SIDEBOARDS_FIVE_PER_FIELD',
	CHAINS = 'CHAINS',
	STOP_BARS = 'STOP_BARS',
	CARGO_NET = 'CARGO_NET',
	COIL_SUPPORTS = 'COIL_SUPPORTS',
	TIR_CABLE = 'TIR_CABLE',
	ANGLES_STRAPS_PLY_FOR_ALL_PALLETS = 'ANGLES_STRAPS_PLY_FOR_ALL_PALLETS',
	CORFLUTE = 'CORFLUTE'
}

export type LocationOwnerShipper = {
	shipper_id: number;
	company_name: string;
	trade_name: string;
	business_name: string;
}

export type LocationOwnerCarrier = {
	carrier_id: number;
	company_name: string;
	trade_name: string;
}

export type LocationOwnersResponse = {
	shippers: LocationOwnerShipper[];
	carriers: LocationOwnerCarrier[];
}
export type LoadingOptionsMap = {
	[key in LoadingOptions]: boolean | null;
};
export enum LocationStep {
	DETAILS = 'details',
	LOADING = 'loadings',
	CONTACT = 'contacts',
	SHIPPER = 'shippers'
}

export type TimePeriod = {
	start: string;
	end: string;
}
export type OpeningTimes = {
	Monday?: TimePeriod | null;
	Tuesday?: TimePeriod | null;
	Wednesday?: TimePeriod | null;
	Thursday?: TimePeriod | null;
	Friday?: TimePeriod | null;
	Saturday?: TimePeriod | null;
	Sunday?: TimePeriod | null;
}
export type LocationFormState = {
	locationName: string;
	address: string;
	locationType: string[]
	openingTimes: OpeningTimes;
	blackoutPeriods: {
		startDate: string;
		endDate: string;
	}[];
	timeSlotRequired: boolean;
	timeSlotProcessInfo: string|null;
	defaultDriverInstructions: string;
	restrictedTrucks: {truckType: number, truckSize: number}[];
	ppeInductionRequired: boolean;
	ppeInductionNotes: string;
	ppeRequired: boolean;
	loadingOptions: LoadingOptionsMap;
	callBeforeArrival: boolean;
	accessCardRequired: boolean;
	siteEntryRequirements: string;
	loadRestraints: string[];
	dangerousGoodsAllowed: boolean;
	ppeList: string[];
	dangerousGoodsTypes: string[];
	dangerousGoodsHandling: string;
	ownerShipperId: number | null;
	ownerShipperName: string;
	ownerCarrierId: number | null;
	ownerCarrierName: string;
}
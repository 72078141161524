import { getTruckTypeLabel } from './truckSizeTypesLabels';
import { TRUCK_TYPE } from '@types';

/**
 * value is the id in eos
 * alias is the enum value available in shipment-api
 */
export const getTruckTypes = [
	{
		label: getTruckTypeLabel(TRUCK_TYPE.ANY),
		value: 14,
		alias: TRUCK_TYPE.ANY,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TIPPER),
		value: 13,
		alias: TRUCK_TYPE.TIPPER,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER),
		value: 1,
		alias: TRUCK_TYPE.TAUTLINER,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK),
		value: 4,
		alias: TRUCK_TYPE.TAUTLINER_STRAIGHT_DECK,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_DROP_DECK),
		value: 5,
		alias: TRUCK_TYPE.TAUTLINER_DROP_DECK,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK),
		value: 6,
		alias: TRUCK_TYPE.TAUTLINER_MEZZANINE_DECK,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.PANTECH),
		value: 2,
		alias: TRUCK_TYPE.PANTECH,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.FLAT_TOP),
		value: 3,
		alias: TRUCK_TYPE.FLAT_TOP,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.SIDELOADER),
		value: 7,
		alias: TRUCK_TYPE.SIDELOADER,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.SKELETON),
		value: 8,
		alias: TRUCK_TYPE.SKELETON,
	},
	{
		label: getTruckTypeLabel(TRUCK_TYPE.OPEN_TOP),
		value: 10,
		alias: TRUCK_TYPE.OPEN_TOP,
	}
];
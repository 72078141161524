import { getTruckSizeENUMVal } from './truckSizeTypesLabels';
import { TRUCK_SIZE } from '@types';

export const getTruckSizeVals = [
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.RIGID),
		id: 1,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.SINGLE),
		id: 2,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.B_DOUBLE),
		id: 3,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.SKELETON),
		id: 5,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.SIDELOADER),
		id: 6,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.B_DOUBLE_HIGH_CUBE),
		id: 7,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.CONTAINER_20FT_COASTAL),
		id: 8,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.CONTAINER_40FT_COASTAL),
		id: 9,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.CONTAINER_46FT_COASTAL),
		id: 12,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.CONTAINER_48FT_COASTAL),
		id: 13,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.B_TRIPLE),
		id: 10,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.SUPER_B_DOUBLE),
		id: 11,
	},
	{
		value: getTruckSizeENUMVal(TRUCK_SIZE.A_DOUBLE),
		id: 15,
	},
];
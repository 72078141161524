import { useContext } from 'react';
import { capitalizeFirstLetter } from '@utils';
import { PageTitleContext } from '@components/CORE/PageTitle/Context';
import { useLocation } from 'react-router-dom';

export const usePageTitle = () => {
	const pageTitleContext = useContext(PageTitleContext);
	const location = useLocation();

	const cleanTitle = (title: string) => {
		const routes = title.split('/');
		const page = routes[routes.length - 1];
		const cleaned = page.replace(/_/g, ' ');
		return capitalizeFirstLetter(cleaned, true);
	}

	const isWritable = () => {
		let writable = true;
		if (
			pageTitleContext &&
			location.pathname === pageTitleContext.route &&
			!pageTitleContext.writable
		) {
			writable = false;
		}
		return writable;
	}

	const set = (title: string, exact = true, writable = true) => {
		if (isWritable()) {
			const update = {
				...pageTitleContext,
				title: exact ? title : cleanTitle(title),
				route: location.pathname,
				writable
			}
			pageTitleContext && pageTitleContext.update && pageTitleContext.update(update);
			document.title = exact ? title : cleanTitle(title);
		}
	}

	return {
		set,
		pageTitle: `${pageTitleContext?.title}`
	}
}
